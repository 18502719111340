import {
  COMMON_APP_NAME,
  COMMON_INNER_HEIGHT,
  COMMON_INNER_WIDTH,
  COMMON_SAFE_AREA_INSETS,
  COMMON_IS_IOS_SAFARI,
  COMMON_IS_IOS_PWA,
  COMMON_IS_IOS_DEVICE,
  COMMON_IS_ANDROID_DEVICE,
  COMMON_APP_STATE_IS_ACTIVE,
  COMMON_APP_LOADED_AT,
  COMMON_DEPLOYMENT_CONFIG,
  COMMON_QUIZ_RESULT_KEY,
  COMMON_EXPERIMENT_FLAGS_WEB,
  COMMON_CERTIFICATION_AFFILIATE_CODE,
} from '../constants/commonState'

import {
  REQ_FETCH_DEPLOYMENT_CONFIG,
  REQ_FETCH_QUIZ_RESULTS,
  REQ_FETCH_CERTIFICATION_CODE_DETAILS,
} from '../constants/requestTypes'

import {
  REQUEST_UNSTARTED,
  REQUEST_SUCCESS,
} from '../constants/requestStates'

import {
  COMMON_UPDATE_RESET_PASSWORD_TOKEN,
  COMMON_UPDATE_REQUEST_STATE,
  COMMON_UPDATE_EXPERIMENT_FLAGS_WEB,
  COMMON_UPDATE_INNER_HEIGHT,
  COMMON_UPDATE_INNER_WIDTH,
  COMMON_UPDATE_SAFE_AREA_INSETS,
  COMMON_UPDATE_ANDROID_DEFERRED_PROMPT,
  COMMON_UPDATE_IS_IOS_PWA,
  COMMON_UPDATE_NATIVE_APP_OPEN_COUNT,
  COMMON_UPDATE_PUSH_NOTIFICATION_TOKEN,
  COMMON_UPDATE_IN_APP_PURCHASE_PRODUCTS,
  COMMON_UPDATE_IN_APP_PURCHASE_PRODUCTS_LOADED,
  COMMON_UPDATE_CAMERA_PERMISSIONS,
  COMMON_UPDATE_DEEPLINKED_ARTICLE_KEY,
  COMMON_UPDATE_DEEPLINKED_AFFILIATE_ID,
  COMMON_UPDATE_APP_STATE_IS_ACTIVE,
  COMMON_UPDATE_CURRENT_APP_VERSION,
  COMMON_UPDATE_NATIVE_COMPLETION_SOUND_PRELOADED,
  COMMON_UPDATE_APP_ACTION_TYPE,
  COMMON_UPDATE_IS_DEEPLINKED_PAYWALL_ACTION,
  COMMON_UPDATE_IS_DEEPLINKED_DISCOVERY_QUIZ_ACTION,
  COMMON_UPDATE_QUIZ_RESULT_KEY,
  COMMON_UPDATE_DEPLOYMENT_CONFIG,
  COMMON_UPDATE_CERTIFICATION_AFFILIATE_CODE,
  COMMON_RESET_STATE,
} from '../constants/actionTypes'

import {
  SetGlobalI18N,
  isIOSSafari,
  isIOSStandalone,
  isIOSDevice,
  isAndroidDevice,
  resizeInnerHeight,
  resizeInnerWidth,
  currentTime,
} from '../utils'

const safeAreaInsets = require('safe-area-insets')

const defaultCommonState = {
  [COMMON_APP_NAME]: 'NeuroFit',
  [COMMON_APP_STATE_IS_ACTIVE]: true,
  [COMMON_INNER_HEIGHT]: resizeInnerHeight(),
  [COMMON_INNER_WIDTH]: resizeInnerWidth(),
  [COMMON_SAFE_AREA_INSETS]: safeAreaInsets,
  [COMMON_IS_IOS_SAFARI]: isIOSSafari(),
  [COMMON_IS_IOS_DEVICE]: isIOSDevice(),
  [COMMON_IS_ANDROID_DEVICE]: isAndroidDevice(),
  [COMMON_IS_IOS_PWA]: isIOSStandalone(),
  [COMMON_APP_LOADED_AT]: currentTime(),
  [COMMON_QUIZ_RESULT_KEY]: "",
  [COMMON_DEPLOYMENT_CONFIG]: undefined,
  [COMMON_EXPERIMENT_FLAGS_WEB]: {},
  [REQ_FETCH_QUIZ_RESULTS]: {state: REQUEST_UNSTARTED},
  [REQ_FETCH_CERTIFICATION_CODE_DETAILS]: {state: REQUEST_UNSTARTED},
  [COMMON_CERTIFICATION_AFFILIATE_CODE]: ""
};

export default (state = defaultCommonState, action) => {
  switch (action.type) {
    case COMMON_UPDATE_INNER_HEIGHT:
      return {...state, [COMMON_INNER_HEIGHT]: action.payload}
    case COMMON_UPDATE_INNER_WIDTH:
      return {...state, [COMMON_INNER_WIDTH]: action.payload}
    case COMMON_UPDATE_SAFE_AREA_INSETS:
      return {...state, [COMMON_SAFE_AREA_INSETS]: action.payload}
    case COMMON_UPDATE_IS_IOS_PWA:
      return {...state, [COMMON_IS_IOS_PWA]: action.payload}
    case COMMON_UPDATE_EXPERIMENT_FLAGS_WEB:
      return {...state, [COMMON_EXPERIMENT_FLAGS_WEB]: action.payload}
    case COMMON_UPDATE_REQUEST_STATE: {
      return {...state, [action.key]: action.payload}
    }
    case COMMON_UPDATE_APP_STATE_IS_ACTIVE:
      return {...state, [COMMON_APP_STATE_IS_ACTIVE]: action.payload}
    case COMMON_UPDATE_QUIZ_RESULT_KEY:
      return {...state, [COMMON_QUIZ_RESULT_KEY]: action.payload}
    case COMMON_UPDATE_DEPLOYMENT_CONFIG:
      return {...state, [COMMON_DEPLOYMENT_CONFIG]: action.payload}
    case COMMON_UPDATE_CERTIFICATION_AFFILIATE_CODE:
      return {...state, [COMMON_CERTIFICATION_AFFILIATE_CODE]: action.payload}
    case COMMON_RESET_STATE:
      return defaultCommonState
    default:
      return state;
  }
};
