import React from 'react';


import { CSSTransition } from 'react-transition-group';
import { range } from 'lodash-es';

import { history } from '../history'

import {
  AppleSvg,
  AndroidSvg,
  NeuroFitNamedLogoSvg,
} from '../constants/svgs'

import {
  subdomainUrls,
  recordLinkClickGA,
  ProductionLinksBaseUrl,
  NeuroFitUniversalAppStoreLink,
  NeuroFitAppleAppStoreLink,
  NeuroFitGooglePlayStoreLink,
  ProductionDashboardSignupUrl,
  WellAndGoodArticleLink,
  ShapeArticleLink,
  NeuroFitSkoolCommunityLink,
} from '../utils'

import Helmet from './common/Helmet'

const containerStyle = {textAlign: "start", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}


class LinksPage extends React.Component {
  constructor(props){
     super(props)
     this.state = {
        learnMoreRef: React.createRef(),
        headerColor: "transparent",
        dropShadowColor: "transparent",
        show: true,
        showIframe: false,
     }
  }

  handleOnClickLearnMore = (event) => {
      //.current is verification that your element has rendered
      if(this.state.learnMoreRef.current){
          this.state.learnMoreRef.current.scrollIntoView({ 
             behavior: "smooth", 
             block: "nearest"
          })
      }
  }

  updateHeaderColorOnContainerScroll = e => {
    if (e.target.scrollTop > 0) {
      const alpha = Math.min(0.5, e.target.scrollTop / 500)
      const headerColor = `rgba(255, 255, 255, ${alpha/1.5})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/3.0})`
      this.setState({headerColor, dropShadowColor/* `rgba(53, 63, 106, ${alpha}`*/})
    } else {
      this.setState({headerColor: "transparent", dropShadowColor: "transparent"})
    }

    if (e.target.scrollTop > 400) {
      this.setState({showIframe: true})
    }
  }

  componentDidMount() {
    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateHeaderColorOnContainerScroll)
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      formFields,
      isIOSDevice,
      isAndroidDevice,
    } = this.props

    const {show, learnMoreRef, scrollingContainerRef, headerColor, dropShadowColor, showIframe} = this.state

    return (
      <div style={{height: innerHeight}}>
        <Helmet
          title={"NEUROFIT Links"}
          description={"Social Media + Press Release Links for NEUROFIT - the industry-leading nervous system regulation app, available for iOS & Android."}
          href={ProductionLinksBaseUrl}
        />
        <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden", backgroundColor: "#EDEEF2"}}>
          <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
          <div style={{position: "fixed", top: 0, left: 0, right: 0, boxShadow: "0px 2px 0px 0px black", display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 12 + safeAreaInsets.top, paddingInlineStart: "min(40px, 7.5%)", paddingInlineEnd: 12, paddingBottom: 12, backgroundColor: "white", zIndex: 1000}}>
            <NeuroFitNamedLogoSvg
              logoSize={"min(4.25vw, 18px)"}
              color={"#000000"}
              onClick={() => window.location = "/"}
            />
          </div>
          <div 
            id={"landing-page-container"}
            className={"disable-scrollbars"}
            ref={elem => this.scrollingContainerRef = elem}
            style={containerStyle}
          >
            <div style={{textAlign: "start", width: "100vw",}}>
              <div style={{color: "black", width: "100%", display: "flex", minHeight: innerHeight, justifyContent: "center", alignItems: "top", marginTop: "calc(60px + 3vh)"}}>
                <div style={{width: "85%", maxWidth: 700}}>
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <div style={{fontFamily: "Futura", maxWidth: 400, width: "100%", textAlign: "start", textTransform: "uppercase", fontSize: "min(7.5vw, 32px)", marginTop: "3vh"}}>
                      {"PRODUCTS"}
                    </div>
                  </div>
                  {(isIOSDevice || isAndroidDevice) ? (
                    <div style={{display: "flex", justifyContent: "center"}}>
                      <button
                        style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 32, color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                        onClick={() => {
                          recordLinkClickGA("Universal App Download Link")
                          window.open(isIOSDevice ? NeuroFitAppleAppStoreLink : NeuroFitGooglePlayStoreLink, "_blank", "noopener noreferrer nofollow")
                        }}
                      >
                        {isIOSDevice ? (
                          <AppleSvg />
                        ) : (isAndroidDevice ? (
                          <AndroidSvg />
                        ) : null)}
                        <span>
                          {"DOWNLOAD THE APP"}
                        </span>
                      </button>
                    </div>
                  ) : (
                    <div>
                      <div style={{display: "flex", justifyContent: "center"}}>
                        <button
                          style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 32, color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                          onClick={() => {
                            recordLinkClickGA("Universal App Download Link")
                            window.open(NeuroFitAppleAppStoreLink, "_blank", "noopener noreferrer nofollow")
                          }}
                        >
                          <AppleSvg />
                          <span>
                            {"IOS APP"}
                          </span>
                        </button>
                      </div>
                      <div style={{display: "flex", justifyContent: "center"}}>
                        <button
                          style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 32, color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                          onClick={() => {
                            recordLinkClickGA("Universal App Download Link")
                            window.open(NeuroFitGooglePlayStoreLink, "_blank", "noopener noreferrer nofollow")
                          }}
                        >
                          <AndroidSvg />
                          <span>
                            {"ANDROID APP"}
                          </span>
                        </button>
                      </div>
                    </div>
                  )}
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <button
                      style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 32, color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                      onClick={() => {
                        window.location = "/certification"
                      }}
                    >
                      <span>
                        {"COACH CERTIFICATION"}
                      </span>
                    </button>
                  </div>
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <button
                      style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 32, color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                      onClick={() => {
                        window.location = NeuroFitSkoolCommunityLink
                      }}
                    >
                      <span>
                        {"RESET COMMUNITY"}
                      </span>
                    </button>
                  </div>
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <div style={{fontFamily: "Futura", maxWidth: 400, width: "100%", textAlign: "start", textTransform: "uppercase", fontSize: "min(7.5vw, 32px)", marginTop: "7vh"}}>
                      {"MEDIA COVERAGE"}
                    </div>
                  </div>
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <button
                      style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 32, color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                      onClick={() => {
                        window.location = WellAndGoodArticleLink
                      }}
                    >
                      <span>
                        {"WELL + GOOD FEATURE"}
                      </span>
                    </button>
                  </div>
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <button
                      style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 32, color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                      onClick={() => {
                        window.location = ShapeArticleLink
                      }}
                    >
                      <span>
                        {"SHAPE INNOVATION ISSUE"}
                      </span>
                    </button>
                  </div>
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <div style={{fontFamily: "Futura", maxWidth: 400, width: "100%", textAlign: "start", textTransform: "uppercase", fontSize: "min(7.5vw, 32px)", marginTop: "7vh"}}>
                      {"QUICK LINKS"}
                    </div>
                  </div>
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <button
                      style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 32, color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                      onClick={() => {
                        window.location = "/blog"
                      }}
                    >
                      <span>
                        {"BLOG"}
                      </span>
                    </button>
                  </div>
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <button
                      style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 32, color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                      onClick={() => {
                        window.location = ProductionDashboardSignupUrl
                      }}
                    >
                      <span>
                        {"BECOME AN AFFILIATE"}
                      </span>
                    </button>
                  </div>
{/*                  <div style={{display: "flex", justifyContent: "center"}}>
                    <button
                      style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 32, color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                      onClick={() => {
                        window.location = "/performance-coaching"
                      }}
                    >
                      <span>
                        {"GET WEALTH-FIT W/ LOREN"}
                      </span>
                    </button>
                  </div>
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <button
                      style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 32, color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                      onClick={() => {
                        window.location = "/performance-coaching?npid=andrew"
                      }}
                    >
                      <span>
                        {"GET LOVE-FIT W/ ANDREW"}
                      </span>
                    </button>
                  </div>*/}
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <button
                      style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 32, color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                      onClick={() => {
                        window.location = "/"
                      }}
                    >
                      <span>
                        {"HOMEPAGE"}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div style={{fontSize: "min(6.5vw, 35px)", textAlign: "center", fontFamily: "Futura", marginTop: 64, marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                {"Nervous System Fitness™"}
              </div>
            </div>
            <div style={{textAlign: "start", width: "100vw"}}>
              <div style={{marginTop: 15, padding: 15, fontSize: "min(3.5vw, 16px)", fontFamily: "Futura", display: "flex", alignItems: "center", "justifyContent": "space-between"}}>
                <div style={{display: "inline-block"}}>
                  <span style={{color: "#c1c4d2"}}>
                    {"© NEUROFIT 2023"}
                  </span>
                  <span style={{display: "inline-block", color: "#c1c4d2", margin: "0px 0.5em", fontSize: "min(2vw, 10px)"}}>
                    {"•"}
                  </span>
                  <span style={{color: "#c1c4d2"}}>
                    {"ALL RIGHTS RESERVED"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LinksPage;