import React from 'react'


import { CSSTransition, SwitchTransition} from 'react-transition-group';

import {
  CertificationPageVideoScriptSrc,
  loadScriptAsync,
} from '../../utils'

const exitButtonStyle = {pointerEvents: "all", margin: "15px 25px", height: 50, width: 50, backgroundColor: "#353F6A", borderRadius: "50%", boxShadow: "0px 0px 10px #353F6A", border: "none"}


class CertificationVslModal extends React.Component {

  constructor(props){

    super(props)

    this.state = {
      iFrameLoaded: false
    }

  }

  render() {

    const {
      // State
      show,
      isNative,
      innerHeight,
      innerWidth,
      onClose,
      safeAreaInsets
    } = this.props

    const {iFrameLoaded} = this.state

    const videoWidth = Math.min(innerWidth * 0.85, 600 - 60)
    const videoAspectRatio = 1080 / 1920

    return (
      <CSSTransition
        in={show}
        timeout={500}
        classNames="redeem-access-code-modal"
        onEntered={() => {
          const {isNative} = this.props
          if (!isNative) {
            loadScriptAsync(CertificationPageVideoScriptSrc)
          }
        }}
        unmountOnExit
      >
        <div
          style={{
            position: 'fixed',
            top: safeAreaInsets.top,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: show ? "rgba(0, 0, 0, 0.2)" : "transparent",
            backdropFilter: show ? "blur(4px)" : "none",
            WebkitBackdropFilter: show ? "blur(4px)" : "none",
            zIndex: 10000000
          }}
          onClick={(e) => {
            onClose()
            e.stopPropagation()
          }}
        >
          <div 
            id={"cert-vsl-modal"}
            style={{
              position: 'absolute',
              background: 'white',
              border: "2px black solid",
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              outline: 'none',
              width: "calc(95% - 4px)",
              maxWidth: 600,
              left: 0,
              right: 0,
              margin: "0px auto",
              top: (safeAreaInsets.top) / 2 + (innerHeight / 4),
              height: Math.min(600, (innerHeight - safeAreaInsets.top) / 2),
            }}
            onClick={e => e.stopPropagation()}
          >
            <div style={{color: "white", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
              <span
                className="close"
                onClick={() => {
                  onClose()
                }}
              >
                &times;
              </span>
              <div style={{padding: 30, color: "#000"}}>
                <div style={{fontSize: "min(6vw, 24px)", fontFamily: "Futura"}}>
                  {"CERTIFICATION PREVIEW"}
                </div>
                <div style={{marginBottom: 15, marginTop: "2vh", fontSize: "min(5.5vw, 22px)", textAlign: "start", fontFamily: "Futura Light"}}>
                  <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", marginTop: `calc(2vh)`}}>
                    <div style={{width: videoWidth, height: videoAspectRatio * videoWidth, maxWidth: 600, border: "3px #000000 solid", overflow: "hidden"}} className={iFrameLoaded ? "" : "video-loading-background-animation"}>
                      <div id="vidalytics_embed_tQUR9L_L5b4ZIhKh" style={{width: videoWidth, height: videoAspectRatio * videoWidth}}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    )
  }
}

export default CertificationVslModal