import React from 'react'

import { CSSTransition, SwitchTransition} from 'react-transition-group';

import {
  OvalSpinnerSvg,
} from '../../constants/svgs'

import {
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
} from '../../constants/requestStates'

import CompletionCheckIcon from './CompletionCheckIcon'

const SendRequestButton = ({
  defaultContent,
  defaultContentText,
  dimButtonText,
  backgroundColor,
  loadingText,
  successText,
  showCustomSuccessIcon,
  textTransform,
  successIcon,
  isLightTheme,
  fontSize,
  fontWeight,
  requestState,
  cancelOverflow,
  isDisabled,
  marginTop,
  padding,
  isSubmit,
  onClick
}) => {
  const buttonBackgroundColor = !!(backgroundColor) ? backgroundColor : (isLightTheme ? "#FFFFFF" : "#000000")
  const buttonBorder = isLightTheme ? "2px #000000 solid" : "none"
  const contentColor = isLightTheme ? "#000000" : "#FFFFFF"

  const isFetching = requestState === REQUEST_FETCHING
  const isSuccess = requestState === REQUEST_SUCCESS

  const loadingContent = loadingText || defaultContent
  const successContent = successText || defaultContent
  return (
    <button
      style={{display: "block", marginTop, width: "100%", fontFamily: "Futura", fontWeight: fontWeight || 420, padding: padding || "12px 15px", color: contentColor, backgroundColor: buttonBackgroundColor, border: buttonBorder, pointerEvents: "all", cursor: isDisabled ? "not-allowed" : "pointer"}}
      type={isSubmit ? "submit" : undefined}
      disabled={isDisabled}
      onClick={() => (!isDisabled) && (!!onClick) && onClick()}
    >
      <SwitchTransition mode="out-in">
        <CSSTransition
          timeout={450}
          classNames={"hide-fade-in-400"}
          unmountOnExit
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          key={`${requestState}-${defaultContentText}`}
        >
          <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", opacity: dimButtonText ? 0.3 : undefined, transition: dimButtonText ? "opacity 150ms linear" : undefined}}>
            <div style={{display: "inline-block", textTransform: textTransform || "uppercase", fontSize: fontSize || "min(6vw, 25px)", lineHeight: fontSize || "min(8vw, 30px)", maxWidth: (!isFetching && !isSuccess) ? "100%" : "calc(100% - 25px)", overflow: cancelOverflow ? "unset" : "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>
              {isFetching ? loadingContent : (isSuccess ? successContent : defaultContent)}
            </div>
            {(isSuccess || isFetching) && (
              <span style={{fontSize: fontSize || "min(6vw, 25px)", width: "0.35em"}} />
            )}
            {isFetching && (
              <OvalSpinnerSvg
                  size={fontSize || "min(5.5vw, 22px)"}
                  color={contentColor}
                />
            )}
            {isSuccess && (
              (!!(showCustomSuccessIcon) ? (
                successIcon
              ) : (
                <CompletionCheckIcon
                  checkColorComplete={buttonBackgroundColor}
                  checkboxBaseClassName={isLightTheme ? "checkbox-request-button-black" : "checkbox-request-button"}
                  isComplete
                  animateCheck
                />
              )
            ))}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </button>
  )
}

export default SendRequestButton