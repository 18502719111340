import {
  QUIZ_PAGE_UPDATE_REQUEST_STATE,
  QUIZ_PAGE_RESET_STATE,
} from '../constants/actionTypes'

import {
  REQ_SAVE_QUIZ_RESULTS,
  REQ_FETCH_QUIZ_RESULTS,
} from '../constants/requestTypes'

import {
  REQUEST_UNSTARTED,
  REQUEST_SUCCESS
} from '../constants/requestStates'

import { combineReducers } from 'redux';


const defaultRequestResults = {
  // [REQ_SAVE_QUIZ_RESULTS]: {state: REQUEST_SUCCESS, result: {"result": {"meta": {"first_name": "Andrew", "created_at_unix": 1661649642, "result_url": "http://localhost:4100/quiz/r/O5HOmf850kb2n697djSXTmVGM13I0jH7envz755T"}, "score": {"overall": 0.7100000000000001, "symptoms": {"total": 0.5, "physical": 0.75, "mental": 0.3333333333333333, "emotional": 0.3333333333333333}, "habits": {"total": 0.48583333333333334, "embody": 0.5, "prevent": 1.0, "nourish": 0.825}, "life_aspects": {"total": 0.75, "breakdown": {"parasympathetic": 0.5, "play": 0.125, "stillness": 0.125, "sympathetic": 0.125, "dorsal_vagal": 0.125, "overwhelm": 0.0}, "energy": 0.0}}}}},
  [REQ_SAVE_QUIZ_RESULTS]: {state: REQUEST_UNSTARTED},
  [REQ_FETCH_QUIZ_RESULTS]: {state: REQUEST_UNSTARTED},
}
function requestResults(
  state = defaultRequestResults, action) {
  switch (action.type) {
    case QUIZ_PAGE_UPDATE_REQUEST_STATE:
      return {...state, [action.key]: {...action.payload}};
    case QUIZ_PAGE_RESET_STATE:
      return defaultRequestResults;
    default:
      return state;
  }
}

export default combineReducers({
  requestResults,
});
