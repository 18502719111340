import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import React, { Suspense } from 'react';
import { history } from './history'
import { store } from './store-web';

import { Router, Route, Switch } from 'react-router-dom';

import AppLoadingPage from './components/AppLoadingPage'

import { 
  resizeInnerHeight,
} from './utils'

import {
  REQUEST_FETCHING,
} from './constants/requestStates'

import loadable from '@loadable/component'

import App from './entrypoints/WebsiteApp'

require('intersection-observer');

const safeAreaInsets = require('safe-area-insets')

require('./style.css');

const innerHeight = resizeInnerHeight()

ReactDOM.render((
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route path="/" render={props => (
          <App {...props} />
        )} />
      </Switch>
    </Router>
  </Provider>

), document.getElementById('root'));
