import React from 'react'

import { InView } from 'react-intersection-observer';

import { CSSTransition, SwitchTransition } from 'react-transition-group';

import {
  baseDomain,
  DefaultCdnBaseUrl,
  DefaultAspectRatio,
  preloadImagePaths
} from '../../utils'

class ScrollAnimatedHeroImageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isHeroImageLoaded: false,
    }
  }

  componentDidMount() {
    const {featureImagePaths} = this.props
    preloadImagePaths(featureImagePaths)
  }


  render() {
    const {
      widthPixels,
      aspectRatio,
      heroImagePath,
      featureImagePaths,
      currentImageIndex,
      onHeroImageLoad
    } = this.props

    const {
      isHeroImageLoaded
    } = this.state

    const currentImageSrc = (currentImageIndex === 0) ? heroImagePath : featureImagePaths[currentImageIndex-1]

    return (
      <InView triggerOnce threshold={0.01} fallbackInView={true} as="div" onChange={(inView, entry) => {
        if (inView) {
          this.setState({videoIsVisible: true})
        }
      }}>
        <div 
          style={{width: widthPixels, height: widthPixels * (aspectRatio || DefaultAspectRatio)}}
        >
          <img
            width={"100%"}
            height={"100%"}
            src={currentImageSrc}
            alt="phone-flip-hero"
            title="phone-flip-hero"
            style={{pointerEvents: "none"}} 
            onLoad={() => {
              if (currentImageSrc === heroImagePath && !isHeroImageLoaded) {
                this.setState({isHeroImageLoaded: true})
                !!onHeroImageLoad && onHeroImageLoad()
              }
            }}
          />
        </div>
      </InView>
    )
  }
}

export default ScrollAnimatedHeroImageContainer;