import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  resetCertificationVideoPage,
  certificationVideoPageUpdateRequestState,
  certificationVideoPageTriggerRequest,
} from '../actions/certificationVideoPage';

import {
  toggleBodyScroll,
  hideLoadingScreen,
  initializeGA,
  pageViewGA,
  recordSignupFunnelEventGA,
  getAdvertisementIdFromQueryString,
  setAdvertisementReferralUserProperty,
  isGDPRSensitive,
  getCoachProfileLink,
  validateUrl,
} from '../utils'

import CoachProfilePage from '../components/CoachProfilePage';

import AppLoadingPageContainer from './AppLoadingPageContainer'

import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../constants/requestStates'

import {
  COMMON_INNER_HEIGHT,
  COMMON_EXPERIMENT_FLAGS_WEB,
  COMMON_SAFE_AREA_INSETS,
  COMMON_IS_NATIVE,
  COMMON_IS_IOS_DEVICE,
  COMMON_IS_ANDROID_DEVICE,
} from '../constants/commonState'

import {
  FORM_FIELD_HIDE_INTRO_VIDEO,
} from '../constants/formFields'

import {
  GA_LABEL_LOADED_CERTIFICATION_VIDEO_PAGE,
} from '../constants/gaEventLabels'

import agent from '../agent'

class CoachProfilePageContainer extends Component {
  constructor(props){
    super(props)

    this.state = {
      username: this.props.match.params.coachProfileUsername,
      fetchCoachProfileResultState: REQUEST_UNSTARTED,
      coachProfileDetails: null,
      isValidPersonalUrl: false
    }
  }

  async componentWillMount() {
    pageViewGA();
    await recordSignupFunnelEventGA(GA_LABEL_LOADED_CERTIFICATION_VIDEO_PAGE)

    await this.fetchCoachProfileData()
  }

  componentWillUnmount() {
    this.props.dispatch(resetCertificationVideoPage());
  }

  async fetchCoachProfileData() {
    const {username} = this.state

    this.setState({...this.state, fetchCoachProfileResultState: REQUEST_FETCHING})
    try {
      const trainingRequestResult = await agent.NeuroFitBackend.getCoachProfileMetadata({username})
      this.setState({...this.state, fetchCoachProfileResultState: REQUEST_SUCCESS, coachProfileDetails: trainingRequestResult})
      
      const isValidPersonalUrl = validateUrl(trainingRequestResult.workplace_url)
      this.setState({isValidPersonalUrl})
    } catch (err) {
      this.setState({...this.state, fetchCoachProfileResultState: REQUEST_ERROR})
    }
  }

  render() {
    const {
      fetchCoachProfileResultState,
      coachProfileDetails,
      isValidPersonalUrl,
    } = this.state

    const isCoachProfileLoaded = (fetchCoachProfileResultState === REQUEST_SUCCESS)

    return (isCoachProfileLoaded ? (
      <CoachProfilePage
        // State
        innerHeight={this.props.innerHeight}
        safeAreaInsets={this.props.safeAreaInsets}
        isIOSDevice={this.props.isIOSDevice}
        isAndroidDevice={this.props.isAndroidDevice}
        iOSAppIsAvailable={this.props.iOSAppIsAvailable}
        androidAppIsAvailable={this.props.androidAppIsAvailable}
        experimentFlags={this.props.experimentFlags}
        requestResults={this.props.requestResults}
        username={this.props.username}
        isValidPersonalUrl={isValidPersonalUrl}
        coachProfileDetails={coachProfileDetails}
        onUpdateRequestState={(requestType, body) => this.props.dispatch(certificationVideoPageUpdateRequestState(requestType, REQUEST_UNSTARTED, body))}
        onResetRequestState={requestType => this.props.dispatch(certificationVideoPageUpdateRequestState(requestType, REQUEST_UNSTARTED))}
        onTriggerRequest={requestType => this.props.dispatch(certificationVideoPageTriggerRequest(requestType))}
        // Actions
      />
    ) : (
      <AppLoadingPageContainer
        appLoadResultState={fetchCoachProfileResultState}
        appLoadErrorMessage={"Error: Coaching Profile failed to load."}
        appReloadButtonText={"Retry"}
        onReloadApp={async () => await this.fetchCoachProfileData()}
        props={this.props}
      />
    ))
  }
}

CoachProfilePageContainer.propTypes = {
  requestResults: PropTypes.object.isRequired,
  experimentFlags: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  innerHeight: PropTypes.number.isRequired,
  isIOSDevice: PropTypes.bool.isRequired,
  isAndroidDevice: PropTypes.bool.isRequired,
  safeAreaInsets: PropTypes.object.isRequired,
  username: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  ...state.coachProfilePage,
  experimentFlags: state.common[COMMON_EXPERIMENT_FLAGS_WEB],
  innerHeight: state.common[COMMON_INNER_HEIGHT],
  isIOSDevice: state.common[COMMON_IS_IOS_DEVICE],
  isAndroidDevice: state.common[COMMON_IS_ANDROID_DEVICE],
  safeAreaInsets: state.common[COMMON_SAFE_AREA_INSETS],
});

export default connect(mapStateToProps)(CoachProfilePageContainer);
