import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  resetLoginPage,
} from '../actions/loginPage';

import {
  toggleBodyScroll,
  hideLoadingScreen,
  initializeGA,
  pageViewGA,
} from '../utils'

import LorenPage from '../components/LorenPage';

import {
  REQ_FETCH_APP_CONFIG,
} from '../constants/requestTypes'

import {
  COMMON_INNER_HEIGHT,
  COMMON_INNER_WIDTH,
  COMMON_SAFE_AREA_INSETS,
  COMMON_IS_NATIVE,
  COMMON_IS_IOS_DEVICE,
  COMMON_IS_ANDROID_DEVICE,
} from '../constants/commonState'

import {
  FORM_FIELD_HIDE_INTRO_VIDEO,
} from '../constants/formFields'

class LorenPageContainer extends Component {
  componentWillMount() {
    initializeGA(true/*disableCookies*/);
    pageViewGA();
  }

  componentDidMount() {
    hideLoadingScreen()
  }
  componentWillUnmount() {
    this.props.dispatch(resetLoginPage());
  }

  render() {
    return (
      <LorenPage
        // State
        innerHeight={this.props.innerHeight}
        innerWidth={this.props.innerWidth}
        safeAreaInsets={this.props.safeAreaInsets}
        isIOSDevice={this.props.isIOSDevice}
        isAndroidDevice={this.props.isAndroidDevice}
        iOSAppIsAvailable={this.props.iOSAppIsAvailable}
        androidAppIsAvailable={this.props.androidAppIsAvailable}
        countryCode={this.props.countryCode}
        // Actions
      />
    );
  }
}

LorenPageContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  innerHeight: PropTypes.number.isRequired,
  innerWidth: PropTypes.number.isRequired,
  isIOSDevice: PropTypes.bool.isRequired,
  isAndroidDevice: PropTypes.bool.isRequired,
  safeAreaInsets: PropTypes.object.isRequired,
  countryCode: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  ...state.loginPage,
  innerHeight: state.common[COMMON_INNER_HEIGHT],
  innerWidth: state.common[COMMON_INNER_WIDTH],
  isIOSDevice: state.common[COMMON_IS_IOS_DEVICE],
  isAndroidDevice: state.common[COMMON_IS_ANDROID_DEVICE],
  safeAreaInsets: state.common[COMMON_SAFE_AREA_INSETS],
  countryCode: "US",
});

export default connect(mapStateToProps)(LorenPageContainer);
