import React from 'react'

import {
  NeuroFitUniversalAppStoreLink,
} from '../../utils'

import {
  VerifiedSvg,
} from '../../constants/svgs'

import { CSSTransition, SwitchTransition} from 'react-transition-group';

const exitButtonStyle = {pointerEvents: "all", margin: "15px 25px", height: 50, width: 50, backgroundColor: "#353F6A", borderRadius: "50%", boxShadow: "0px 0px 10px #353F6A", border: "none"}


class SatisfactionGuaranteeModal extends React.Component {

  constructor(props){
     super(props)
  }

  render() {

    const {
      // State
      show,
      safeAreaInsets,
      innerHeight,
      paymentSuccess,
      onClose
    } = this.props

    return (
      <CSSTransition
        in={show}
        timeout={500}
        classNames="redeem-access-code-modal"
        unmountOnExit
      >
        <div
          style={{
            position: 'fixed',
            top: safeAreaInsets.top,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: show ? "rgba(0, 0, 0, 0.2)" : "transparent",
            backdropFilter: show ? "blur(4px)" : "none",
            WebkitBackdropFilter: show ? "blur(4px)" : "none",
            zIndex: 1000000
          }}
          onClick={() => onClose()}
        >
          <div 
            id={"app-offline-modal"}
            style={{
              position: 'absolute',
              background: 'white',
              border: "2px black solid",
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              outline: 'none',
              width: "calc(90% - 4px)",
              maxWidth: 600,
              left: 0,
              right: 0,
              margin: "0px auto",
              top: (safeAreaInsets.top) / 2 + (innerHeight / 4),
              maxHeight: Math.min(600, (innerHeight - safeAreaInsets.top) / 1.5),
            }}
            onClick={e => e.stopPropagation()}
          >
            <div style={{color: "white", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
              <div style={{width: "85%", height: "100%", color: "#000", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
                <div>
                  <div>
                    <div style={{display: "flex", marginTop: "3vh", alignItems: "center", justifyContent: "left"}}>
                      <div style={{display: "inline-block", marginInlineStart: "0.3em", fontSize: "min(6.5vw, 30px)", textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                        {"30-Day Satisfaction Guarantee"}
                      </div>
                    </div>
                    <div style={{padding: "min(2vw, 10px) 0px", fontSize: "min(5vw, 20px)"}}>
                      <div style={{fontFamily: "Futura Book", marginTop: "3vh"}}>
                        {`2,000+ wellness professionals use our products - and we're confident this certification will serve you well too.`}
                      </div>
                      <div style={{fontFamily: "Futura Book", marginTop: "1vh", marginBottom: "3vh"}}>
                        {`If you purchase the Certification via pay in full, complete all course requirements within 30 days of purchase, and still aren’t satisfied, let us know why and we'll refund you no questions asked.`}
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  style={{display: "block", marginBottom: "4vh", textTransform: "uppercase", width: "100%", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", color: "#ffffff", backgroundColor: "#000000", padding: "12px 15px", border: "none"}}
                  onClick={() => {
                    onClose()
                  }}
                >
                  {"Close"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    )
  }
}

export default SatisfactionGuaranteeModal;