import {
  LOGIN_PAGE_UPDATE_FORM_FIELD,
  LOGIN_PAGE_TRIGGER_REQUEST,
  LOGIN_PAGE_UPDATE_REQUEST_STATE,
  LOGIN_PAGE_TOGGLE_MODAL,
  LOGIN_PAGE_RESET_STATE,

} from '../constants/actionTypes';

import {
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../constants/requestStates';

export const resetLoginPage = () => {
  return {
    type: LOGIN_PAGE_RESET_STATE
  }
};

export const triggerRequest = (key) => {
  return {
    type: LOGIN_PAGE_TRIGGER_REQUEST + key,
    payload: {
      key,
    }
  }
};

export const loginPageUpdateRequestState = (key, state, result=undefined) => {
  let payload = {
    state,
  };
  if (!!(result)) {
    if (state === REQUEST_SUCCESS) {
      payload.result = result
    }
    if (state === REQUEST_ERROR) {
      payload.error = result;
    }
  }
  return {
    type: LOGIN_PAGE_UPDATE_REQUEST_STATE,
    key,
    payload,
  }
}

export const updateFormField = (key, value) => {
  return {
    type: LOGIN_PAGE_UPDATE_FORM_FIELD,
    payload: {[key]: value}
  }
};

export const loginPageToggleModal = (key, showModal) => {
  return {
    type: LOGIN_PAGE_TOGGLE_MODAL,
    payload: {[key]: showModal}
  }
}
