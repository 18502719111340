import React from 'react'

import { CSSTransition, SwitchTransition } from 'react-transition-group';

import LoadingLogo from './LoadingLogo'
import NeuroFitProgressRing from './NeuroFitProgressRing'
import AutoPlayInlineVideoContainer from './AutoPlayInlineVideoContainer'

import { InView } from 'react-intersection-observer';

import {
  formatContentDuration,
  convertToArticleDate,
} from '../../utils'

import {
  LanguageCodeEnglish,
  getLocalizedPublicArticleUrl,
} from '../../languageUtils'

import {
  ClockSvg,
} from '../../constants/svgs'

const tagsStyle = {display: "flex", justifyContent: "start", alignItems: "center", flexWrap: "wrap", flexDirection: "row"}
const tagStyle = {fontFamily: "Futura", fontSize: 12, padding: "1px 1px", color: "#eeeeee", opacity: 0.8, display: "inline-block", margin: "4px 4px 4px 0px"}
const tagSeparatorStyle = {color: "#cccccc", opacity: 1.0}
const tagCategoryNameStyle = {opacity: 0.8, height: 22, width: 22, color: "#cccccc", margin: 5}

const contentTitleTextStyle = {
  display: '-webkit-box',
  color: "#000000",
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontFamily: "Futura Medium",
  fontSize: "min(3.5vw, 16px)",
  textOverflow: "ellipsis"
};

const contentDescriptionTextStyle = {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3,
  marginTop: "0.3em",
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontFamily: "Futura Book",
  color: "#000000",
  fontSize: "min(3vw, 14px)",
  textAlign: "start"
}

class StaticArticleRecommendation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isThumbnailLoaded: false,
      hasBecomeVisible: false
    }
  }

  render() {
    const {
      articleData,
      languageCode,
    } = this.props

    const {isThumbnailLoaded, hasBecomeVisible} = this.state

    const resolvedThumbnailSrc = hasBecomeVisible ? articleData.thumbnail_image_url : ""

    const localizedDurationMinutesString = new Intl.NumberFormat(languageCode, { style: 'unit', unit: 'minute', unitDisplay: 'short' }).format(articleData.read_duration_minutes)

    return (
      <InView triggerOnce threshold={0.01} fallbackInView={true} as="div" onChange={(inView, entry) => {
        if (inView) {
          this.setState({hasBecomeVisible: true})
        }
      }}>
        <div
          style={{marginBottom: "3vh", color: "white", fontFamily: "Futura", border: "none", width: "min(100%, 500px)", borderRadius: 0, height: 100, backgroundColor: "#FFFFFF", boxShadow: "0px 0px 1.5px 1px #999", overflow: "visible", display: "block", padding: 0, border: "none"}}
        >
          <div style={{textDecoration: "none", padding: 0, backgroundColor: "transparent", fontFamily: "Futura", textAlign: "start", overflow: "hidden", width: "100%", height: 90, border: "none"}}>
            <div style={{display: "flex", alignItems: "top", justifyContent: "start"}}>
              <div className={(isThumbnailLoaded) ? "article-loaded-background" : "article-loading-background-animation"} style={{height: 100, minWidth: 100, width: 100, backgroundColor: (isThumbnailLoaded) ? "#000000" : "transparent", transition: "background-color 250ms linear"}}>
                <img onLoad={() => {this.setState({isThumbnailLoaded: true})}} style={{width: 100, height: 100, opacity: (isThumbnailLoaded ? 1.0 : 0.0), pointerEvents: "none", backdropFilter: isThumbnailLoaded ? "blur(10px)" : "none", WebkitBackdropFilter: isThumbnailLoaded ? "blur(10px)" : "none", transition: "all 250ms linear", transitionProperty: "opacity, -webkit-backdrop-filter, backdrop-filter"}} src={resolvedThumbnailSrc} alt={articleData.title} />
              </div>
              <div style={{padding: "min(2.5vw, 10px)", borderInlineStart: "1px #DEE0E8 solid", width: "100%"}}>
                <div className={"article-loaded-background"} style={{backgroundColor: "transparent", lineHeight: "min(4vw, 17px)", width: "100%", minHeight: "min(8vw, 34px)", maxHeight: 90, ...contentTitleTextStyle}}>
                  <div>
                    <a href={getLocalizedPublicArticleUrl(languageCode, articleData.article_slug)} target="_blank" style={{marginInlineEnd: "0.3em"}}>
                      {articleData.title}
                    </a>
                    <span style={{display: "inline-flex", alignItems: "top", lineHeight: "min(4vw, 17px)"}}>
                      <ClockSvg
                        size={"min(2.8vw, 9px"}
                        color={"#000000"}
                      />
                      <span style={{textTransform: "uppercase", fontSize: "min(3vw, 10px)", lineHeight: "min(3vw, 10px)", fontFamily: "Futura"}}>{`${localizedDurationMinutesString}`}</span>
                    </span>
                  </div>
                  <div style={contentDescriptionTextStyle}>{articleData.description}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </InView>
    )
  }
}

export default StaticArticleRecommendation