import React from 'react';

import {
  VerifiedSvg,
  NeuroFitLogoSvg,
} from '../../constants/svgs'

import StarRatings from 'react-star-ratings'

const getFillColor = (value, baselineAvailable, valueBaseline, valueBaselineStdDev, isIncreasingStatistic) => {
  if (!baselineAvailable) {
    return "#000000"
  } else {
    return "#000000"
    // const baselineDeviation = (value - valueBaseline)
    // if (Math.abs(baselineDeviation) < (1.5 * valueBaselineStdDev)) {
    //   return "#000000"
    // } else if (baselineDeviation > (1.5 * valueBaselineStdDev)) {
    //   return isIncreasingStatistic ? "#93E29A" : "#FF7CBC"
    // } else {
    //   return isIncreasingStatistic ? "#FF7CBC" : "#93E29A"
    // }
  }
}


const InlineTrainerTestimonial = ({
  name,
  marginTop,
  occupation,
  countryFlag,
  profileImageSrc,
  certifiedText,
  testimonialText,
  backgroundColor,
  titleColor,
  blurProfileImage,
  titleFontFamily
}) => {


  return (
    <div key={name} style={{display: "flex", justifyContent: "center", marginTop: marginTop || 0, marginBottom: "4vh"}}>
      <div style={{padding: "min(3vw, 16px)", maxWidth: 400, backgroundColor: backgroundColor || "#FFFFFF", boxShadow: "0px 0px 1.5px 1.5px #dddddd", fontSize: "min(6vw, 25px)"}}>
        <div style={{display: "flex", alignItems: "center", justifyContent: "left", marginTop: "min(1.5vw, 9px)"}}>
          {!!(profileImageSrc) ? (
            <div style={{height: 45, width: 45, marginRight: 10, borderRadius: "50%", backgroundColor: "#EDEEF2", boxShadow: "0px 0px 1px 0.5px #bbbbbb"}}>
              <img src={profileImageSrc} style={{height: "100%", width: "100%", borderRadius: "50%", overflow: "hidden", filter: blurProfileImage ? "blur(1.5px)" : "none"}} />
            </div>
          ) : (
            <NeuroFitLogoSvg
              size={"45px"}
              color={"#000000"}
              lineColor={"#FFFFFF"}
              margin={"0px 10px 0px 0px"}
            />
          )}
          <div>
            <div style={{textTransform: "uppercase", color: titleColor || "#999999", fontFamily: titleFontFamily || "Futura", fontSize: "min(3.5vw, 18px)", lineHeight: "min(3.5vw, 18px)"}}>
              {`${name}, ${occupation} ${countryFlag}`}
            </div>
            <div style={{display: "flex", alignItems: "center", justifyContent: "left"}}>
              <VerifiedSvg size={"min(3.5vw, 18px)"} color={"#999999"} />
              <div style={{color: "#999999", textTransform: "uppercase", marginLeft: "0.2em", fontFamily: "Futura", fontSize: "min(3.5vw, 18px)"}}>
                {certifiedText || "NEUROFIT-Certified"}
              </div>
            </div>
          </div>
        </div>
        {(!!testimonialText) && (
          <div style={{fontFamily: "Futura", fontStyle: "italic", fontSize: "min(3.8vw, 18px)", marginTop: "min(3vw, 16px)"}}>
            {`"${testimonialText}"`}
          </div>
        )}
      </div>
    </div>
  )
}

export default InlineTrainerTestimonial