import React from 'react';


import { CSSTransition } from 'react-transition-group';
import { range } from 'lodash-es';

import TeamsOptInFormDetails from './common/TeamsOptInFormDetails'

import { history } from '../history'

import {
  AppleSvg,
  AndroidSvg,
  NeuroFitNamedLogoSvg,
} from '../constants/svgs'

import {
  GA_LABEL_TEAMS_CLICKED_CERTIFICATION,
} from '../constants/gaEventLabels'

import {
  REQ_SUBMIT_CERTIFICATION_VIDEO_PAGE_DETAILS,
} from '../constants/requestTypes'

import {
  EXPERIMENT_FLAGS_WEB_CERT_OFFER_USE_VSL,
} from '../constants/experimentFlags'

import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../constants/requestStates'

import {
  subdomainUrls,
  recordLinkClickGA,
  ProductionTeamsOptInUrl,
  NeuroFitUniversalAppStoreLink,
  NeuroFitAppleAppStoreLink,
  NeuroFitGooglePlayStoreLink,
  CertificationCheckoutLink,
  recordPixelEventIfAvailable,
  recordLinkClickAndRedirect,
  identifyCertificationAnalyticsWebUser,
  configureAnalyticsUserProperties,
  getPixelParams,
  getCampaignCodeFromQueryString,
  recordFunnelEventGA,
  delay,
} from '../utils'

import Helmet from './common/Helmet'

import agent from '../agent'

class TeamsOptInPage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      headerBackgroundColor: "transparent",
      dropShadowColor: "transparent",
      headerBorderColor: "transparent",
      headerBackgroundBlur: "none",
      logoOutlineColor: "#FFFFFF",
      topPageScrollProgress: 0,
      trainingSignUpResultState: REQUEST_UNSTARTED,
      attemptedDuplicateOptIn: false
    }
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      formFields,
      isNative,
      isIOSDevice,
      isAndroidDevice,
      isNasmPage,
      requestResults,
      onTriggerRequest,
      onUpdateRequestState,
      experimentFlags
    } = this.props

    const {headerBorderColor, attemptedDuplicateOptIn, headerBackgroundColor, headerBackgroundBlur, logoOutlineColor, textShadow, topPageScrollProgress, trainingSignUpResultState} = this.state
    
    const appDownloadUrl = isIOSDevice ? NeuroFitAppleAppStoreLink : (isAndroidDevice ? NeuroFitGooglePlayStoreLink : NeuroFitUniversalAppStoreLink)

    return (
      <div style={{height: innerHeight}}>
        <Helmet
          title={"How To Recover From Burnout | Teams + Organizations | NEUROFIT"}
          description={"Resolve team + organization burnout fast, with industry-leading tech trusted by 2,000+ health professionals."}
          href={ProductionTeamsOptInUrl}
        />
        <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden", backgroundColor: "#FFFFFF"}}>
          <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
          <div style={{position: "fixed", top: 0, left: 0, right: 0, borderBottom: `2px ${headerBorderColor} solid`, display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 12 + safeAreaInsets.top, paddingInlineStart: "min(40px, 7.5%)", paddingInlineEnd: "min(20px, 4%)", paddingBottom: 12, backgroundColor: headerBackgroundColor, zIndex: 1000, backdropFilter: headerBackgroundBlur, WebkitBackdropFilter: headerBackgroundBlur}}>
            <NeuroFitNamedLogoSvg
              logoSize={"min(4.25vw, 18px)"}
              color={"#000000"}
              dropShadowColor={logoOutlineColor}
            />
          </div>
          <TeamsOptInFormDetails
            innerHeight={innerHeight}
            safeAreaInsets={safeAreaInsets}
            onContainerScroll={updatedState => this.setState({...updatedState})}
            onClickCertificationButton={() => recordLinkClickAndRedirect(GA_LABEL_TEAMS_CLICKED_CERTIFICATION, "/certification", false)}
          />
        </div>
      </div>
    )
  }
}

export default TeamsOptInPage;
