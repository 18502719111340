import React from 'react';

import {
  loadingScreenIsVisible,
} from '../utils'

import {
  NeuroFitNamedLogoSvg,
} from '../constants/svgs'

import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_ERROR
} from '../constants/requestStates';

const AppLoadingPage = ({
  height,
  safeAreaInsets,
  appLoadResultState,
  appLoadErrorMessage,
  appReloadButtonText,
  // Actions
  onReloadApp,
}) => {
  const loadingScreenVisible = loadingScreenIsVisible()
  return (
    <div style={{position: "absolute", top: 0, left: 0, bottom: 0, width: "100vw", zIndex: 100, backgroundColor: "#EDEEF2"}}>
      <div style={{position: "relative", top: 0, left: 0, height: safeAreaInsets.top, opacity: loadingScreenVisible ? 0 : 1, transition: "opacity 200ms linear 200ms", width: "100vw", backgroundColor: "#000000"}}>
      </div>
      <div style={{position: "absolute", top: 0, left: 0, width: "100vw", bottom: 0, justifyContent: "center", display: "flex", alignItems: "center"}}>
        {((appLoadResultState === REQUEST_FETCHING) || (appLoadResultState === REQUEST_UNSTARTED)) && (
          <NeuroFitNamedLogoSvg
            logoSize={"29.6px"}
            color={"#000000"}
          />
        )}
        {(appLoadResultState === REQUEST_ERROR) && (
          <div style={{display: "flex", margin: "0% 5%"}}>
            <div className="lp-subheader">
              <div style={{marginBottom: "3vh"}}>{appLoadErrorMessage}</div>
              <button
                style={{backgroundColor: "black", color: "white", padding: "12px 15px", border: "none", textTransform: "uppercase", fontFamily: "Futura", fontWeight: "normal", fontSize: "min(6vw, 24px)"}}
                onClick={onReloadApp}
              >
                {appReloadButtonText}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AppLoadingPage;