import React from 'react'

const Checkbox = ({
  isChecked,
  setIsChecked,
  isDisabled,
  checkboxClass,
  style
}) => {
  const selectedCheckboxClass = checkboxClass || "checkbox"
  let className = selectedCheckboxClass + (isChecked ? ` ${selectedCheckboxClass}--active` : "")
  const appliedStyle = !!(style) ? style : {height: "min(4vw, 15px)"}
  return (
    <div 
      disabled={isDisabled}
      style={{...appliedStyle, opacity: isDisabled ? 0.55 : 1.0, transition: "opacity 150ms linear", cursor: isDisabled ? "not-allowed" : "pointer"}}
      onClick={() => {
        if (!isDisabled) {
          setIsChecked(!isChecked)
        }}
      }
    >
      <input
        type="checkbox"
        disabled={isDisabled}
      />
      <svg
        className={className}
        disabled
        // This element is purely decorative so
        // we hide it for screen readers
        aria-hidden="true"
        viewBox="0 0 15 11"
        fill="none"
      >
        <path
          d="M1 4.5L5 9L14 1"
          strokeWidth="3"
          stroke={isChecked ? "#fff" : "none"}
        />
      </svg>
    </div>
  );
}

export default Checkbox;