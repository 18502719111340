export const COMMON_UPDATE_RESET_PASSWORD_TOKEN = 'COMMON_UPDATE_RESET_PASSWORD_TOKEN'
export const COMMON_UPDATE_CLIENT_TESTIMONIAL_TOKEN = 'COMMON_UPDATE_CLIENT_TESTIMONIAL_TOKEN'
export const COMMON_TRIGGER_REQUEST = 'COMMON_TRIGGER_REQUEST__'
export const COMMON_UPDATE_REQUEST_STATE = 'COMMON_UPDATE_REQUEST_STATE'
export const COMMON_RESET_STATE = 'COMMON_RESET_STATE'
export const COMMON_UPDATE_INNER_HEIGHT = 'COMMON_UPDATE_INNER_HEIGHT'
export const COMMON_UPDATE_INNER_WIDTH = 'COMMON_UPDATE_INNER_WIDTH'
export const COMMON_UPDATE_VISIBLE_KEYBOARD_HEIGHT = 'COMMON_UPDATE_VISIBLE_KEYBOARD_HEIGHT'
export const COMMON_UPDATE_SAFE_AREA_INSETS = 'COMMON_UPDATE_SAFE_AREA_INSETS'
export const COMMON_UPDATE_ANDROID_DEFERRED_PROMPT = 'COMMON_UPDATE_ANDROID_DEFERRED_PROMPT'
export const COMMON_UPDATE_IS_IOS_PWA = 'COMMON_UPDATE_IS_IOS_PWA'
export const COMMON_UPDATE_NATIVE_APP_OPEN_COUNT = 'COMMON_UPDATE_NATIVE_APP_OPEN_COUNT'
export const COMMON_UPDATE_PUSH_NOTIFICATION_TOKEN = 'COMMON_UPDATE_PUSH_NOTIFICATION_TOKEN'
export const COMMON_UPDATE_IN_APP_PURCHASE_PRODUCTS = 'COMMON_UPDATE_IN_APP_PURCHASE_PRODUCTS'
export const COMMON_UPDATE_IN_APP_PURCHASE_PRODUCTS_INTRO_TRIAL_AVAILABLE = 'COMMON_UPDATE_IN_APP_PURCHASE_PRODUCTS_INTRO_TRIAL_AVAILABLE'
export const COMMON_UPDATE_IN_APP_PURCHASE_PRODUCTS_LOADED = 'COMMON_UPDATE_IN_APP_PURCHASE_PRODUCTS_LOADED'
export const COMMON_UPDATE_IN_APP_PURCHASE_PRODUCTS_LOAD_ERROR = 'COMMON_UPDATE_IN_APP_PURCHASE_PRODUCTS_LOAD_ERROR'
export const COMMON_UPDATE_CAMERA_PERMISSIONS = 'COMMON_UPDATE_CAMERA_PERMISSIONS'
export const COMMON_UPDATE_DEEPLINKED_ARTICLE_KEY = 'COMMON_UPDATE_DEEPLINKED_ARTICLE_KEY'
export const COMMON_UPDATE_APP_STATE_IS_ACTIVE = 'COMMON_UPDATE_APP_STATE_IS_ACTIVE'
export const COMMON_UPDATE_CURRENT_APP_VERSION = 'COMMON_UPDATE_CURRENT_APP_VERSION'
export const COMMON_UPDATE_NATIVE_COMPLETION_SOUND_PRELOADED = 'COMMON_UPDATE_NATIVE_COMPLETION_SOUND_PRELOADED'
export const COMMON_UPDATE_APP_ACTION_TYPE = 'COMMON_UPDATE_APP_ACTION_TYPE'
export const COMMON_UPDATE_IS_DEEPLINKED_PAYWALL_ACTION = 'COMMON_UPDATE_IS_DEEPLINKED_PAYWALL_ACTION'
export const COMMON_UPDATE_IS_DEEPLINKED_DISCOVERY_QUIZ_ACTION = 'COMMON_UPDATE_IS_DEEPLINKED_DISCOVERY_QUIZ_ACTION'
export const COMMON_UPDATE_QUIZ_RESULT_KEY = 'COMMON_UPDATE_QUIZ_RESULT_KEY'
export const COMMON_UPDATE_CERTIFICATION_AFFILIATE_CODE = 'COMMON_UPDATE_CERTIFICATION_AFFILIATE_CODE'
export const COMMON_UPDATE_DEPLOYMENT_CONFIG = 'COMMON_UPDATE_DEPLOYMENT_CONFIG'
export const COMMON_UPDATE_DEEPLINKED_AFFILIATE_CODE = 'COMMON_UPDATE_DEEPLINKED_AFFILIATE_CODE'
export const COMMON_UPDATE_DEEPLINKED_CERT_PAGE_SECTION_KEY = 'COMMON_UPDATE_DEEPLINKED_CERT_PAGE_SECTION_KEY'
export const COMMON_UPDATE_DEEPLINKED_CERT_TESTIMONIAL_CATEGORY_KEY = 'COMMON_UPDATE_DEEPLINKED_CERT_TESTIMONIAL_CATEGORY_KEY'
export const COMMON_UPDATE_DEEPLINKED_CERT_CHECKOUT_PLAN_KEY = 'COMMON_UPDATE_DEEPLINKED_CERT_CHECKOUT_PLAN_KEY'
export const COMMON_UPDATE_NATIVE_APP_IS_OFFLINE = 'COMMON_UPDATE_NATIVE_APP_IS_OFFLINE'
export const COMMON_UPDATE_EXPERIMENT_FLAGS = 'COMMON_UPDATE_EXPERIMENT_FLAGS'
export const COMMON_UPDATE_EXPERIMENT_FLAGS_WEB = 'COMMON_UPDATE_EXPERIMENT_FLAGS_WEB'
export const COMMON_UPDATE_DEVICE_IDENTIFIER = 'COMMON_UPDATE_DEVICE_IDENTIFIER'
export const COMMON_UPDATE_SELECTED_APP_ICON = 'COMMON_UPDATE_SELECTED_APP_ICON'
export const COMMON_UPDATE_EXISTING_APP_OPEN_URL = 'COMMON_UPDATE_EXISTING_APP_OPEN_URL'
export const COMMON_UPDATE_IS_MAC_OS_RUNTIME = 'COMMON_UPDATE_IS_MAC_OS_RUNTIME'
export const COMMON_UPDATE_LANGUAGE_CODE = 'COMMON_UPDATE_LANGUAGE_CODE'
export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';

export const QUIZ_PAGE_UPDATE_FORM_FIELD = 'QUIZ_PAGE_UPDATE_FORM_FIELD'
export const QUIZ_PAGE_TRIGGER_REQUEST = 'QUIZ_PAGE_TRIGGER_REQUEST'
export const QUIZ_PAGE_UPDATE_REQUEST_STATE = 'QUIZ_PAGE_UPDATE_REQUEST_STATE'
export const QUIZ_PAGE_RESET_STATE = 'QUIZ_PAGE_RESET_STATE'

export const CREATOR_APPLICATION_PAGE_UPDATE_FORM_FIELD = 'CREATOR_APPLICATION_PAGE_UPDATE_FORM_FIELD'
export const CREATOR_APPLICATION_PAGE_TRIGGER_REQUEST = 'CREATOR_APPLICATION_PAGE_TRIGGER_REQUEST'
export const CREATOR_APPLICATION_PAGE_UPDATE_REQUEST_STATE = 'CREATOR_APPLICATION_PAGE_UPDATE_REQUEST_STATE'
export const CREATOR_APPLICATION_PAGE_RESET_STATE = 'CREATOR_APPLICATION_PAGE_RESET_STATE'

export const WORKSHOPS_PAGE_UPDATE_FORM_FIELD = 'WORKSHOPS_PAGE_UPDATE_FORM_FIELD'
export const WORKSHOPS_PAGE_TRIGGER_REQUEST = 'WORKSHOPS_PAGE_TRIGGER_REQUEST'
export const WORKSHOPS_PAGE_UPDATE_REQUEST_STATE = 'WORKSHOPS_PAGE_UPDATE_REQUEST_STATE'
export const WORKSHOPS_PAGE_RESET_STATE = 'WORKSHOPS_PAGE_RESET_STATE'

export const AZURE_CLUB_RSVP_PAGE_UPDATE_FORM_FIELD = 'AZURE_CLUB_RSVP_PAGE_UPDATE_FORM_FIELD'
export const AZURE_CLUB_RSVP_PAGE_TRIGGER_REQUEST = 'AZURE_CLUB_RSVP_PAGE_TRIGGER_REQUEST'
export const AZURE_CLUB_RSVP_PAGE_UPDATE_REQUEST_STATE = 'AZURE_CLUB_RSVP_PAGE_UPDATE_REQUEST_STATE'
export const AZURE_CLUB_RSVP_PAGE_RESET_STATE = 'AZURE_CLUB_RSVP_PAGE_RESET_STATE'

export const CERTIFICATION_VIDEO_PAGE_UPDATE_FORM_FIELD = 'CERTIFICATION_VIDEO_PAGE_UPDATE_FORM_FIELD'
export const CERTIFICATION_VIDEO_PAGE_TRIGGER_REQUEST = 'CERTIFICATION_VIDEO_PAGE_TRIGGER_REQUEST'
export const CERTIFICATION_VIDEO_PAGE_UPDATE_REQUEST_STATE = 'CERTIFICATION_VIDEO_PAGE_UPDATE_REQUEST_STATE'
export const CERTIFICATION_VIDEO_PAGE_RESET_STATE = 'CERTIFICATION_VIDEO_PAGE_RESET_STATE'

export const TRAINING_REGISTRATION_PAGE_UPDATE_FORM_FIELD = 'TRAINING_REGISTRATION_PAGE_UPDATE_FORM_FIELD'
export const TRAINING_REGISTRATION_PAGE_TRIGGER_REQUEST = 'TRAINING_REGISTRATION_PAGE_TRIGGER_REQUEST'
export const TRAINING_REGISTRATION_PAGE_UPDATE_REQUEST_STATE = 'TRAINING_REGISTRATION_PAGE_UPDATE_REQUEST_STATE'
export const TRAINING_REGISTRATION_PAGE_RESET_STATE = 'TRAINING_REGISTRATION_PAGE_RESET_STATE'

export const LOGIN_PAGE_UPDATE_FORM_FIELD = 'LOGIN_PAGE_UPDATE_FORM_FIELD'
export const LOGIN_PAGE_TRIGGER_REQUEST = 'LOGIN_PAGE_TRIGGER_REQUEST__'
export const LOGIN_PAGE_UPDATE_REQUEST_STATE = 'LOGIN_PAGE_UPDATE_REQUEST_STATE'
export const LOGIN_PAGE_TOGGLE_MODAL = 'LOGIN_PAGE_TOGGLE_MODAL'
export const LOGIN_PAGE_RESET_STATE = 'LOGIN_PAGE_RESET_STATE'

export const LOGGED_IN_PAGE_UPDATE_FORM_FIELD = 'LOGGED_IN_PAGE_UPDATE_FORM_FIELD';
export const LOGGED_IN_PAGE_UPDATE_PAGE_STATE = 'LOGGED_IN_PAGE_UPDATE_PAGE_STATE'
export const LOGGED_IN_PAGE_TRIGGER_REQUEST = 'LOGGED_IN_PAGE_TRIGGER_REQUEST__';
export const LOGGED_IN_PAGE_UPDATE_REQUEST_STATE = 'LOGGED_IN_PAGE_UPDATE_REQUEST_STATE';
export const LOGGED_IN_PAGE_TOGGLE_MODAL = 'LOGGED_IN_PAGE_TOGGLE_MODAL';
export const LOGGED_IN_PAGE_UPDATE_HRV_CAPTURE_STATE = 'LOGGED_IN_PAGE_UPDATE_HRV_CAPTURE_STATE';
export const LOGGED_IN_PAGE_RESET_HRV_CAPTURE_STATE = 'LOGGED_IN_PAGE_RESET_HRV_CAPTURE_STATE';
export const LOGGED_IN_PAGE_UPDATE_CLEAR_JOURNAL_STATE = 'LOGGED_IN_PAGE_UPDATE_CLEAR_JOURNAL_STATE';
export const LOGGED_IN_PAGE_RESET_CLEAR_JOURNAL_STATE = 'LOGGED_IN_PAGE_RESET_CLEAR_JOURNAL_STATE';
export const LOGGED_IN_PAGE_RESET_STATE = 'LOGGED_IN_PAGE_RESET_STATE';
export const LOGGED_IN_PAGE_RESET_MODALS_EXCEPT_FORCE_UPDATE = 'LOGGED_IN_PAGE_RESET_MODALS_EXCEPT_FORCE_UPDATE';


export const DASHBOARD_LOGGED_IN_PAGE_UPDATE_FORM_FIELD = 'DASHBOARD_LOGGED_IN_PAGE_UPDATE_FORM_FIELD'
export const DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST = 'DASHBOARD_LOGGED_IN_PAGE_TRIGGER_REQUEST'
export const DASHBOARD_LOGGED_IN_PAGE_UPDATE_REQUEST_STATE = 'DASHBOARD_LOGGED_IN_PAGE_UPDATE_REQUEST_STATE'
export const DASHBOARD_LOGGED_IN_PAGE_UPDATE_PAGE_STATE = 'DASHBOARD_LOGGED_IN_PAGE_UPDATE_PAGE_STATE'
export const DASHBOARD_LOGGED_IN_PAGE_RESET_STATE = 'DASHBOARD_LOGGED_IN_PAGE_RESET_STATE'