import React from 'react';


import { CSSTransition } from 'react-transition-group';
import { range } from 'lodash-es';

import { history } from '../history'

import {
  subdomainUrls,
  recordLinkClickGA,
  ProductionSupportBaseUrl,
  getCurrentYear,
} from '../utils'

import {
  NeuroFitNamedLogoSvg,
} from '../constants/svgs'

import Helmet from './common/Helmet'

const containerStyle = {textAlign: "start", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}


class SupportPage extends React.Component {
  constructor(props){
     super(props)
     this.state = {
        learnMoreRef: React.createRef(),
        headerColor: "transparent",
        dropShadowColor: "transparent",
        show: true,
        showIframe: false,
     }
  }

  handleOnClickLearnMore = (event) => {
      //.current is verification that your element has rendered
      if(this.state.learnMoreRef.current){
          this.state.learnMoreRef.current.scrollIntoView({ 
             behavior: "smooth", 
             block: "nearest"
          })
      }
  }

  updateHeaderColorOnContainerScroll = e => {
    if (e.target.scrollTop > 0) {
      const alpha = Math.min(0.5, e.target.scrollTop / 500)
      const headerColor = `rgba(255, 255, 255, ${alpha/1.5})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/3.0})`
      this.setState({headerColor, dropShadowColor/* `rgba(53, 63, 106, ${alpha}`*/})
    } else {
      this.setState({headerColor: "transparent", dropShadowColor: "transparent"})
    }

    if (e.target.scrollTop > 400) {
      this.setState({showIframe: true})
    }
  }

  componentDidMount() {
    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateHeaderColorOnContainerScroll)
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      formFields,
      isNative
    } = this.props

    const {show, learnMoreRef, scrollingContainerRef, headerColor, dropShadowColor, showIframe} = this.state

    const currentYear = getCurrentYear()

    return (
      <div style={{height: innerHeight}}>

        <Helmet
          title={"NEUROFIT Customer Care | Nervous System Dysregulation App"}
          description={"Contact NEUROFIT Customer Care for help with our industry-leading nervous system regulation app."}
          href={ProductionSupportBaseUrl}
        />
        <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden", backgroundColor: "#EDEEF2"}}>
          <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
          <div style={{position: "fixed", top: 0, left: 0, right: 0, boxShadow: "0px 2px 0px 0px black", display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 12 + safeAreaInsets.top, paddingInlineStart: "min(40px, 7.5%)", paddingInlineEnd: 12, paddingBottom: 12, backgroundColor: "white", zIndex: 1000}}>
            <NeuroFitNamedLogoSvg
              logoSize={"min(4.25vw, 18px)"}
              color={"#000000"}
              onClick={() => window.location = "/"}
            />
            {!isNative && (
              <div style={{display: "flex", alignItems: "center"}}>
                <a
                  href={"/"}
                  target="_blank"
                  style={{fontFamily: "Futura Medium", textDecoration: "none", fontSize: "min(4vw, 15px)", textTransform: "uppercase", color: "#000000", cursor: "pointer"}}
                >
                  {"Home"}
                </a>
              </div>
            )}
          </div>
          <div 
            id={"landing-page-container"}
            className={"disable-scrollbars"}
            ref={elem => this.scrollingContainerRef = elem}
            style={containerStyle}
          >
            <div style={{textAlign: "start", width: "100vw"}}>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", marginTop: "20vh"}}>
                <div style={{width: "85%", maxWidth: 700}}>
                  <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em", marginTop: "3vh", textTransform: "uppercase"}}>
                    {`Customer Care`}
                  </div>
                  <h2 style={{marginTop: 20, fontSize: "min(6vw, 26px)", textAlign: "start", fontFamily: "Futura Light"}}>
                    <div style={{display: "inline-block"}}>
                      {"For inquiries, questions, and other customer care, kindly contact our team at:"}
                    </div>
                  </h2>
                  <h3 style={{marginTop: 20, fontSize: "min(6vw, 26px)", textAlign: "start", fontFamily: "Futura"}}>
                    <div style={{display: "inline-block", fontFamily: "Futura"}}>
                      {"care (at) neurofit (dot) app"}
                    </div>
                  </h3>
                  <div style={{marginTop: "max(6vw, 32px)", marginBottom: `calc(max(6vw, 32px) + ${safeAreaInsets.bottom}px)`, display: "flex", justifyContent: "center"}}>
                    <NeuroFitNamedLogoSvg
                      logoSize={"min(4.25vw, 18px)"}
                      color={"#CFD2DD"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={{position: "fixed", bottom: 0, left: 0, right: 0, textAlign: "start", width: "100vw"}}>
              <div style={{marginTop: 15, padding: 15, paddingBottom: 0, fontSize: "min(3.5vw, 16px)", fontFamily: "Futura"}}>
                <div style={{display: "inline-block"}}>
                  <div style={{color: "#c1c4d2"}}>
                    {`Xama Technologies, Inc.`}
                  </div>
                  <div style={{color: "#c1c4d2"}}>
                    {`651 N Broad Street, Suite 206`}
                  </div>
                  <div style={{color: "#c1c4d2"}}>
                    {`Middletown, DE 19709`}
                  </div>
                  <div style={{color: "#c1c4d2"}}>
                    {`Support: care@neurofit.app`}
                  </div>
                  <div style={{color: "#c1c4d2"}}>
                    {`https://neurofit.app`}
                  </div>
                </div>
              </div>
              <div style={{padding: 15, fontSize: "min(3.5vw, 16px)", fontFamily: "Futura", display: "flex", alignItems: "center", "justifyContent": "space-between"}}>
                <div style={{display: "inline-block"}}>
                  <span style={{color: "#c1c4d2"}}>
                    {`© NEUROFIT ${currentYear}`}
                  </span>
                  <span style={{display: "inline-block", margin: "0px 0.5em", color: "#c1c4d2", fontSize: "min(2vw, 10px)"}}>
                    {"•"}
                  </span>
                  <span style={{color: "#c1c4d2"}}>
                    {"ALL RIGHTS RESERVED"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SupportPage;