import React from 'react';


import { CSSTransition } from 'react-transition-group';
import { range } from 'lodash-es';

import { history } from '../history'

import {
  AppleSvg,
  AndroidSvg,
  NeuroFitNamedLogoSvg,
} from '../constants/svgs'

import {
  subdomainUrls,
  recordLinkClickGA,
  ProductionLinksBaseUrl,
  NeuroFitUniversalAppStoreLink,
  NeuroFitAppleAppStoreLink,
  NeuroFitGooglePlayStoreLink,
  EmbodiedCeoCourseLink,
  WellAndGoodArticleLink,
  ShapeArticleLink,
  AuthorityMagazineArticleLink,
  NeuroFitSkoolCommunityLink,
  ProductionDashboardSignupUrl,
  Masterclass100KMonthsCheckoutLink,
  MasterclassDailySalesCheckoutLink,
  MasterclassRichAndRegulatedCheckoutLink,
  Masterclass7FigureOfferSuiteCheckoutLink,
} from '../utils'

import Helmet from './common/Helmet'

const containerStyle = {textAlign: "start", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}


class LorenPage extends React.Component {
  constructor(props){
     super(props)
     this.state = {
        learnMoreRef: React.createRef(),
        headerColor: "transparent",
        dropShadowColor: "transparent",
        show: true,
        showIframe: false,
     }
  }

  handleOnClickLearnMore = (event) => {
      //.current is verification that your element has rendered
      if(this.state.learnMoreRef.current){
          this.state.learnMoreRef.current.scrollIntoView({ 
             behavior: "smooth", 
             block: "nearest"
          })
      }
  }

  updateHeaderColorOnContainerScroll = e => {
    if (e.target.scrollTop > 0) {
      const alpha = Math.min(0.5, e.target.scrollTop / 500)
      const headerColor = `rgba(255, 255, 255, ${alpha/1.5})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/3.0})`
      this.setState({headerColor, dropShadowColor/* `rgba(53, 63, 106, ${alpha}`*/})
    } else {
      this.setState({headerColor: "transparent", dropShadowColor: "transparent"})
    }

    if (e.target.scrollTop > 400) {
      this.setState({showIframe: true})
    }
  }

  componentDidMount() {
    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateHeaderColorOnContainerScroll)
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      formFields,      
      isIOSDevice,
      isAndroidDevice,
    } = this.props

    const {show, learnMoreRef, scrollingContainerRef, headerColor, dropShadowColor, showIframe} = this.state

    return (
      <div style={{height: innerHeight}}>
        <Helmet
          title={"Loren Hogue's Links | NEUROFIT"}
          description={"Social + Press Links for Loren Hogue, Co-CEO of NEUROFIT - the industry-leading nervous system regulation app available for iOS & Android."}
          href={ProductionLinksBaseUrl}
        />
        <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden", backgroundColor: "#FFFFFF"}}>
          <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
          <div style={{position: "fixed", top: 0, left: 0, right: 0, boxShadow: "0px 2px 0px 0px black", display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 12 + safeAreaInsets.top, paddingInlineStart: "min(40px, 7.5%)", paddingInlineEnd: 12, paddingBottom: 12, backgroundColor: "rgba(255, 255, 255, 0.7)", backdropFilter: "blur(7px)", WebkitBackdropFilter: "blur(7px)", zIndex: 1000}}>
            <NeuroFitNamedLogoSvg
              logoSize={"min(4.25vw, 18px)"}
              color={"#000000"}
              onClick={() => window.location = "/"}
            />
          </div>
          <div 
            id={"landing-page-container"}
            className={"disable-scrollbars"}
            ref={elem => this.scrollingContainerRef = elem}
            style={containerStyle}
          >
            <div style={{textAlign: "start", width: "100vw",}}>
              <div style={{color: "black", width: "100%", display: "flex", minHeight: innerHeight, justifyContent: "center", alignItems: "top", marginTop: "calc(60px + 5vh)"}}>
                <div style={{width: "85%", maxWidth: 700}}>
                  <div style={{fontFamily: "Futura Light", fontStyle: "italic", fontSize: "min(5.5vw, 22px)"}}>
                    {"Hi! I'm Loren Hogue - Co-CEO of NEUROFIT. I have one wish for you - to use the tools below to create a life you're deeply in love with. I've curated a few of my favorite products and media features for you below."}
                  </div>
                  <div style={{fontFamily: "Futura Light", fontStyle: "italic", fontSize: "min(5.5vw, 22px)", marginTop: "1.5vh"}}>
                    {"With Love,"}
                  </div>
                  <div style={{marginTop: "1.5vh"}}>
                    <img src="/images/loren-signature.webp" alt="Loren Hogue Signature - CEO of NEUROFIT" style={{width: 150, height: 81.5}} />
                  </div>
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <div style={{fontFamily: "Futura", maxWidth: 400, width: "100%", textAlign: "start", textTransform: "uppercase", fontSize: "min(7.5vw, 32px)", marginTop: "4.5vh"}}>
                      {"My Favorite Tools"}
                    </div>
                  </div>
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <button
                      style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 32, color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                      onClick={() => {
                        window.location = "/certification"
                      }}
                    >
                      <span>
                        {"COACH CERTIFICATION"}
                      </span>
                    </button>
                  </div>
                  {(isIOSDevice || isAndroidDevice) ? (
                    <div style={{display: "flex", justifyContent: "center"}}>
                      <button
                        style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 32, color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                        onClick={() => {
                          recordLinkClickGA("Universal App Download Link")
                          window.open(isIOSDevice ? NeuroFitAppleAppStoreLink : NeuroFitGooglePlayStoreLink, "_blank", "noopener noreferrer nofollow")
                        }}
                      >
                        {isIOSDevice ? (
                          <AppleSvg />
                        ) : (isAndroidDevice ? (
                          <AndroidSvg />
                        ) : null)}
                        <span>
                          {"NEUROFIT APP"}
                        </span>
                      </button>
                    </div>
                  ) : (
                    <div>
                      <div style={{display: "flex", justifyContent: "center"}}>
                        <button
                          style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 32, color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                          onClick={() => {
                            recordLinkClickGA("Universal App Download Link")
                            window.open(NeuroFitAppleAppStoreLink, "_blank", "noopener noreferrer nofollow")
                          }}
                        >
                          <AppleSvg />
                          <span>
                            {"NEUROFIT ON IOS"}
                          </span>
                        </button>
                      </div>
                      <div style={{display: "flex", justifyContent: "center"}}>
                        <button
                          style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 32, color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                          onClick={() => {
                            recordLinkClickGA("Universal App Download Link")
                            window.open(NeuroFitGooglePlayStoreLink, "_blank", "noopener noreferrer nofollow")
                          }}
                        >
                          <AndroidSvg />
                          <span>
                            {"NEUROFIT ON ANDROID"}
                          </span>
                        </button>
                      </div>
                    </div>
                  )}
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <button
                      style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 32, color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                      onClick={() => {
                        window.location = NeuroFitSkoolCommunityLink
                      }}
                    >
                      <span>
                        {"RESET COMMUNITY"}
                      </span>
                    </button>
                  </div>
{/*                  <div style={{display: "flex", justifyContent: "center"}}>
                    <button
                      style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 32, color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                      onClick={() => {
                        window.location = EmbodiedCeoCourseLink
                      }}
                    >
                      <span>
                        {"EMBODIED CEO COURSE"}
                      </span>
                    </button>
                  </div>*/}
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <div style={{fontFamily: "Futura", maxWidth: 400, width: "100%", textAlign: "start", textTransform: "uppercase", fontSize: "min(7.5vw, 32px)", marginTop: "4.5vh"}}>
                      {"Media Features"}
                    </div>
                  </div>
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <button
                      style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 32, color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                      onClick={() => {
                        window.location = WellAndGoodArticleLink
                      }}
                    >
                      <span>
                        {"WELL + GOOD"}
                      </span>
                    </button>
                  </div>
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <button
                      style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 32, color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                      onClick={() => {
                        window.location = ShapeArticleLink
                      }}
                    >
                      <span>
                        {"SHAPE"}
                      </span>
                    </button>
                  </div>
                  <div style={{height: "6vh"}}>
                  </div>
                </div>
              </div>
            </div>
            <div style={{textAlign: "start", width: "100vw"}}>
              <div style={{marginTop: 15, padding: 15, fontSize: "min(3.5vw, 16px)", fontFamily: "Futura", display: "flex", alignItems: "center", "justifyContent": "space-between"}}>
                <div style={{display: "inline-block"}}>
                  <span style={{color: "#c1c4d2"}}>
                    {"© NEUROFIT 2023"}
                  </span>
                  <span style={{display: "inline-block", color: "#c1c4d2", margin: "0px 0.5em", fontSize: "min(2vw, 10px)"}}>
                    {"•"}
                  </span>
                  <span style={{color: "#c1c4d2"}}>
                    {"ALL RIGHTS RESERVED"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LorenPage;