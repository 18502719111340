import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  resetLoginPage,
} from '../actions/loginPage';

import {
  toggleBodyScroll,
  hideLoadingScreen,
  initializeGA,
  pageViewGA,
  recordSignupFunnelEventGA,
  isGDPRSensitive
} from '../utils'

import WebAppCheckoutPage from '../components/WebAppCheckoutPage';

import {
  REQ_FETCH_APP_CONFIG,
} from '../constants/requestTypes'

import {
  GA_LABEL_LOADED_APP_CHECKOUT_PAGE,
} from '../constants/gaEventLabels'

import {
  COMMON_INNER_HEIGHT,
  COMMON_SAFE_AREA_INSETS,
  COMMON_IS_NATIVE,
  COMMON_IS_IOS_DEVICE,
  COMMON_IS_ANDROID_DEVICE,
  COMMON_IOS_VERSION,
  COMMON_EXPERIMENT_FLAGS_WEB,
} from '../constants/commonState'

import {
  FORM_FIELD_HIDE_INTRO_VIDEO,
} from '../constants/formFields'

class WebAppCheckoutPageContainer extends Component {
  async componentWillMount() {
    pageViewGA();
    await recordSignupFunnelEventGA(GA_LABEL_LOADED_APP_CHECKOUT_PAGE)
  }

  componentDidMount() {
    hideLoadingScreen()
  }

  componentWillUnmount() {
    this.props.dispatch(resetLoginPage());
  }

  render() {
    return (
      <WebAppCheckoutPage
        // State
        innerHeight={this.props.innerHeight}
        safeAreaInsets={this.props.safeAreaInsets}
        isIOSDevice={this.props.isIOSDevice}
        isAndroidDevice={this.props.isAndroidDevice}
        iOSVersion={this.props.iOSVersion}
        iOSAppIsAvailable={this.props.iOSAppIsAvailable}
        androidAppIsAvailable={this.props.androidAppIsAvailable}
        experimentFlags={this.props.experimentFlags}
        countryCode={this.props.countryCode}
        // Actions
      />
    );
  }
}

WebAppCheckoutPageContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  innerHeight: PropTypes.number.isRequired,
  isIOSDevice: PropTypes.bool.isRequired,
  isAndroidDevice: PropTypes.bool.isRequired,
  iOSVersion: PropTypes.object.isRequired,
  safeAreaInsets: PropTypes.object.isRequired,
  experimentFlags: PropTypes.object.isRequired,
  countryCode: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  ...state.loginPage,
  innerHeight: state.common[COMMON_INNER_HEIGHT],
  isIOSDevice: state.common[COMMON_IS_IOS_DEVICE],
  isAndroidDevice: state.common[COMMON_IS_ANDROID_DEVICE],
  iOSVersion: state.common[COMMON_IOS_VERSION],
  safeAreaInsets: state.common[COMMON_SAFE_AREA_INSETS],
  experimentFlags: state.common[COMMON_EXPERIMENT_FLAGS_WEB],
  countryCode: "US",
});

export default connect(mapStateToProps)(WebAppCheckoutPageContainer);
