import React from 'react';

import {
  NeuroFitAppleAppStoreLink,
  NeuroFitGooglePlayStoreLink,
  NeuroFitUniversalAppStoreLink,
  recordLinkClickAndRedirect,
  recordPixelEventIfAvailable,
  getAppAffiliateLink,
  getCertificationAffiliateLink,
} from '../../utils'

import LoadingLogo from './LoadingLogo'

import {
  GA_LABEL_CLICKED_APP_AFFILIATE_LINK,
  GA_LABEL_CLICKED_CERTIFICATION_AFFILIATE_LINK,
} from '../../constants/gaEventLabels'

import {
  AppleSvg,
  AndroidSvg,
} from '../../constants/svgs'

const AffiliateDownloadButtonsSection = ({
  isIOSDevice,
  isAndroidDevice,
  useWhiteButtons,
  onShowQrCodeModal,
  affiliateCode,
  maxWidth,
  onDownloadButtonClick
}) => (
  <div>
    {(isIOSDevice || isAndroidDevice) ? (
      <div style={{display: "flex", justifyContent: "center"}}>
        <button
          style={{display: "flex", pointerEvents: "all", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: maxWidth || 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: "3vh", color: useWhiteButtons ? "#000000" : "#ffffff", backgroundColor: useWhiteButtons ? "#FFFFFF" : "#000000", padding: "18px 15px", border: useWhiteButtons ? "2px #000000 solid" : "none"}}
          onClick={() => {
            if (!!onDownloadButtonClick) {
              onDownloadButtonClick()
            }
            const redirectUrl = getAppAffiliateLink(affiliateCode)
            recordLinkClickAndRedirect(GA_LABEL_CLICKED_APP_AFFILIATE_LINK, redirectUrl)
          }}
        >
          {isIOSDevice ? (
            <AppleSvg color={useWhiteButtons ? "#000000" : "#FFFFFF"} />
          ) : (isAndroidDevice ? (
            <AndroidSvg color={useWhiteButtons ? "#000000" : "#FFFFFF"} />
          ) : null)}
          <span>
            {"GET THE APP"}
          </span>
        </button>
      </div>
    ) : (
      <div>
        <div style={{display: "flex", justifyContent: "center"}}>
          <button
            style={{display: "flex", pointerEvents: "all", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: maxWidth || 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: "min(32px, 3vh)", color: useWhiteButtons ? "#000000" : "#ffffff", backgroundColor: useWhiteButtons ? "#FFFFFF" : "#000000", padding: "18px 15px", border: useWhiteButtons ? "2px #000000 solid" : "none"}}
            onClick={() => {
              const redirectUrl = getAppAffiliateLink(affiliateCode)
              recordLinkClickAndRedirect(GA_LABEL_CLICKED_APP_AFFILIATE_LINK, redirectUrl)
            }}
          >
            {"GET THE APP"}
          </button>
        </div>
      </div>
    )}
    <div style={{display: "flex", justifyContent: "center"}}>
      <button
        style={{display: "flex", pointerEvents: "all", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: maxWidth || 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: "min(32px, 3vh)", color: useWhiteButtons ? "#000000" : "#ffffff", backgroundColor: useWhiteButtons ? "#FFFFFF" : "#000000", padding: "18px 15px", border: useWhiteButtons ? "2px #000000 solid" : "none"}}
        onClick={() => {
          const redirectUrl = getCertificationAffiliateLink(affiliateCode)
          recordLinkClickAndRedirect(GA_LABEL_CLICKED_CERTIFICATION_AFFILIATE_LINK, redirectUrl)
        }}
      >
        {"BECOME A COACH"}
      </button>
    </div>
  </div>
)

export default AffiliateDownloadButtonsSection