import React from 'react'

import { InView } from 'react-intersection-observer';

import {
  baseDomain,
  DefaultCdnBaseUrl,
  DefaultAspectRatio
} from '../../utils'

class AutoPlayInlineVideoContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVideoLoaded: false,
      videoIsVisible: false
    }
    this.videoElementRef = React.createRef();

    this.playVideo = this.playVideo.bind(this);
  }

  componentDidMount() {
    if (!!(this.videoElementRef) && !!(this.videoElementRef.current)) {
      this.videoElementRef.current.playbackRate = this.props.playbackRate || 0.75
      // this.videoElementRef.current.play()

      if (this.props.registerManualStartEvent) {
        this.props.registerManualStartEvent(() => {
          this.playVideo()
        })
      }

      if (!!(this.props.onDownloadProgress)) {
        this.videoElementRef.current.addEventListener("progress", () => {
          if (this.videoElementRef.current.buffered.length > 0) {
            var loadedPercentage = this.videoElementRef.current.buffered.end(0) / this.videoElementRef.current.duration
            this.props.onDownloadProgress(loadedPercentage)
          }
        })

        this.videoElementRef.current.addEventListener("canplaythrough", () => {
          this.props.onDownloadProgress(1)
        })
      }
    }
  }

  playVideo() {
    if (!!(this.videoElementRef) && !!(this.videoElementRef.current)) {
      this.videoElementRef.current.playbackRate = this.props.playbackRate || 0.75
      this.videoElementRef.current.play()
    }
  }

  pauseVideo() {
    if (!!(this.videoElementRef) && !!(this.videoElementRef.current)) {
      this.videoElementRef.current.playbackRate = this.props.playbackRate || 0.75
      this.videoElementRef.current.pause()
    }
  }

  render() {
    const {
      videoPath,
      widthPixels,
      aspectRatio,
      isVideoVisible,
      baseContentUrl,
      playbackRate,
      disableAutoPlay,
      onVideoLoad,
      onVideoPlay,
      canManuallyStartVideo,
      hideLoadingAnimation,
      hideLoadedAnimation,
      registerManualStartEvent,
      onDownloadProgress,
      lazyLoadVideo,
      playAudio
    } = this.props

    const {
      isVideoLoaded,
      videoIsVisible
    } = this.state

    const isVideoOnScreen = isVideoLoaded && isVideoVisible

    const resolvedVideoSource = (videoIsVisible || !lazyLoadVideo) ? ((baseContentUrl || DefaultCdnBaseUrl) + videoPath) : undefined

    return (
      <InView triggerOnce threshold={0.01} fallbackInView={true} as="div" onChange={(inView, entry) => {
        if (inView) {
          this.setState({videoIsVisible: true})
        }
      }}>
        <div 
          onClick={() => {
            if (canManuallyStartVideo) {
              this.playVideo()
            }
          }}
          className={hideLoadingAnimation ? "" : ((!isVideoOnScreen) ? "video-loading-background-animation" : "video-loaded-background")} 
          style={{width: widthPixels, height: widthPixels * (aspectRatio || DefaultAspectRatio)}}
        >
          {(!!(videoPath)) && (
            <video
              width={"100%"}
              height={"100%"}
              alt="workout"
              title="workout"
              style={{pointerEvents: "none", opacity: (isVideoOnScreen || hideLoadedAnimation) ? 1.0 : 0.0, backdropFilter: (isVideoOnScreen && !hideLoadedAnimation) ? "blur(10px)" : "none", WebkitBackdropFilter: (isVideoOnScreen && !hideLoadedAnimation) ? "blur(10px)" : "none", transition: !hideLoadedAnimation ? "all 250ms linear" : undefined, transitionProperty: !hideLoadedAnimation ? "opacity, -webkit-backdrop-filter, backdrop-filter" : undefined}}
              muted={!playAudio}
              loop
              playsInline
              autoPlay
              ref={this.videoElementRef}
              onPlay={() => {
                if (!!onVideoPlay) {
                  onVideoPlay()
                }
              }}
              onCanPlayThrough={!isVideoLoaded && (() => {
                this.setState({isVideoLoaded: true})
                if (!!onVideoLoad) {
                  onVideoLoad()
                }
              })}
            >
              {resolvedVideoSource && (
                <source src={resolvedVideoSource} type="video/mp4">
                </source>
              )}
            </video>
          )}
        </div>
      </InView>
    )
  }
}

export default AutoPlayInlineVideoContainer;