import React from 'react';


import { CSSTransition } from 'react-transition-group';
import { range } from 'lodash-es';

import TrainerCertificationVideoDetails from './common/TrainerCertificationVideoDetails'

import { history } from '../history'

import {
  AppleSvg,
  AndroidSvg,
  NeuroFitNamedLogoSvg,
} from '../constants/svgs'

import {
  GA_LABEL_CLICKED_REGISTER_FOR_CERTIFICATION_TRAINING
} from '../constants/gaEventLabels'

import {
  REQ_SUBMIT_CERTIFICATION_VIDEO_PAGE_DETAILS,
} from '../constants/requestTypes'

import {
  EXPERIMENT_FLAGS_WEB_CERT_OFFER_USE_VSL,
} from '../constants/experimentFlags'

import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../constants/requestStates'

import {
  subdomainUrls,
  recordLinkClickGA,
  ProductionCertificationRegistrationUrl,
  NeuroFitUniversalAppStoreLink,
  NeuroFitAppleAppStoreLink,
  NeuroFitGooglePlayStoreLink,
  CertificationCheckoutLink,
  recordPixelEventIfAvailable,
  recordLinkClickAndRedirect,
  identifyCertificationAnalyticsWebUser,
  configureAnalyticsUserProperties,
  getPixelParams,
  getCampaignCodeFromQueryString,
  recordFunnelEventGA,
  delay,
} from '../utils'

import Helmet from './common/Helmet'

import agent from '../agent'

class CertificationPage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      headerBackgroundColor: "transparent",
      dropShadowColor: "transparent",
      headerBorderColor: "transparent",
      headerBackgroundBlur: "none",
      logoOutlineColor: "#EDEEF2",
      topPageScrollProgress: 0,
      trainingSignUpResultState: REQUEST_UNSTARTED,
      attemptedDuplicateOptIn: false
    }
  }
  
  async signUpForTraining(body) {
    const {experimentFlags} = this.props

    recordFunnelEventGA(GA_LABEL_CLICKED_REGISTER_FOR_CERTIFICATION_TRAINING)

    // Add FB Pixel Params to Request Body 
    const {fbp, fbc, fbclid, ttp, ttclid} = getPixelParams()
    const cc = getCampaignCodeFromQueryString()
    const requestBody = {...body, fbp, fbc, fbclid, ttp, ttclid, cc}

    this.setState({...this.state, trainingSignUpResultState: REQUEST_FETCHING})
    try {
      const trainingRequestResult = await agent.NeuroFitBackend.submitCertificationVideoRegistration(requestBody)
      this.setState({...this.state, trainingSignUpResultState: REQUEST_SUCCESS})

      // Pre-warm the Browser Cache with unique code data, then redirect to either VSL or Sales page.
      const unique_code = trainingRequestResult.unique_code
      const unique_code_expiration_days = trainingRequestResult.unique_code_expiration_days

      await identifyCertificationAnalyticsWebUser({uuid: unique_code})
      await configureAnalyticsUserProperties({experimentFlags: {experiment__cert_offer_unique_code_expiration_days: unique_code_expiration_days}})
      await agent.NeuroFitBackend.retrieveCertificationUniqueCodeDetails({unique_code})
      recordPixelEventIfAvailable("Lead", "certification", 99700, unique_code)
      await delay(500)
      const redirectBasePath = "/certification"
      window.location = `${redirectBasePath}?c=${unique_code}`

    } catch (err) {
      this.setState({...this.state, trainingSignUpResultState: REQUEST_ERROR})
    }
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      formFields,
      isNative,
      isIOSDevice,
      isAndroidDevice,
      isNasmPage,
      requestResults,
      onTriggerRequest,
      onUpdateRequestState,
      experimentFlags
    } = this.props

    const {headerBorderColor, attemptedDuplicateOptIn, headerBackgroundColor, headerBackgroundBlur, logoOutlineColor, textShadow, topPageScrollProgress, trainingSignUpResultState} = this.state
    
    const appDownloadUrl = isIOSDevice ? NeuroFitAppleAppStoreLink : (isAndroidDevice ? NeuroFitGooglePlayStoreLink : NeuroFitUniversalAppStoreLink)

    return (
      <div style={{height: innerHeight}}>
        <Helmet
          title={"NEUROFIT Nervous System Certification | Take 50% Off"}
          description={"NEUROFIT Nervous System Certification. Trusted by hundreds of embodied health experts, backed by data. Register now for 50% off."}
          href={ProductionCertificationRegistrationUrl}
        />
        <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden", backgroundColor: "#FFFFFF"}}>
          <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
          <div style={{position: "fixed", top: 0, left: 0, right: 0, borderBottom: `2px ${headerBorderColor} solid`, display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 12 + safeAreaInsets.top, paddingInlineStart: "min(40px, 7.5%)", paddingInlineEnd: "min(20px, 4%)", paddingBottom: 12, backgroundColor: headerBackgroundColor, zIndex: 1000, opacity: topPageScrollProgress, backdropFilter: headerBackgroundBlur, WebkitBackdropFilter: headerBackgroundBlur}}>
            <NeuroFitNamedLogoSvg
              logoSize={"min(4.25vw, 18px)"}
              color={"#000000"}
              dropShadowColor={logoOutlineColor}
            />
          </div>
          <TrainerCertificationVideoDetails
            innerHeight={innerHeight}
            safeAreaInsets={safeAreaInsets}
            isNative={isNative}
            attemptedDuplicateOptIn={attemptedDuplicateOptIn}
            onSignUpForTraining={async (body) => {
              await this.signUpForTraining(body)
            }}
            signUpForTrainingRequestResult={{state: trainingSignUpResultState}}
            onContainerScroll={updatedState => this.setState({...updatedState})}
            experimentFlags={experimentFlags}
          />
        </div>
      </div>
    )
  }
}

export default CertificationPage;