import React from 'react'

const STANFORD_LOGO = "/images/stanford-school-of-medicine.webp?v=3"
const HARVARD_LOGO = "/images/harvard-medical-school.webp?v=3"
const UPENN_LOGO = "/images/upenn.webp?v=3"
const UCHICAGO_LOGO = "/images/uchicago.webp?v=4"
const UCLA_LOGO = "/images/ucla.webp?v=4"
const BERKELEY_LOGO = "/images/uc-berkeley.webp?v=3"
const NORTHWESTERN_LOGO = "/images/northwestern-university.webp?v=3"
const CMU_LOGO = "/images/carnegie-mellon-university.webp?v=3"

export const UniversityLogoImagePaths = [
  STANFORD_LOGO,
  HARVARD_LOGO,
  UPENN_LOGO,
  UCHICAGO_LOGO,
  UCLA_LOGO,
  BERKELEY_LOGO,
  NORTHWESTERN_LOGO,
  CMU_LOGO
]

const UniversitySourcesSection = ({
  isMobile,
  hideFirstRow,
  hideSecondRow,
  lazyLoadImages,
  useLightColors,
  opacity
}) => {
  const loadingStyle = lazyLoadImages ? "lazy" : "eager"
  return (
    isMobile ? (
      <div style={{textAlign: "center", opacity: opacity || 0.7, filter: useLightColors ? "invert(1)" : "none"}}>
        {!hideFirstRow && (
          <div>
            <div style={{display: "flex", width: "100%", justifyContent: "space-around", alignItems: "center", marginTop: "6vh"}}>
              <img src={STANFORD_LOGO} loading={loadingStyle} style={{width: 150, maxWidth: "40%", opacity: 0.6}} alt="Stanford School of Medicine Logo" />
              <img src={HARVARD_LOGO} loading={loadingStyle} style={{width: 150, maxWidth: "40%", opacity: 0.9}} alt="Harvard Medical School Logo" />
            </div>
            <div style={{display: "flex", width: "100%", justifyContent: "space-around", alignItems: "center", marginTop: "4vh"}}>
              <img src={UPENN_LOGO} loading={loadingStyle} style={{width: 150, maxWidth: "40%", opacity: 0.6}} alt="University of Pennsylvania Logo" />
              <img src={UCHICAGO_LOGO} loading={loadingStyle} style={{width: 150, maxWidth: "40%", opacity: 0.6}} alt="University of Chicago Logo" />
            </div>
          </div>
        )}
        {!hideSecondRow && (
          <div>
            <div style={{display: "flex", width: "100%", justifyContent: "space-around", alignItems: "center", marginTop: "4vh"}}>
              <img src={UCLA_LOGO} loading={loadingStyle} style={{width: 150, maxWidth: "40%", opacity: 0.6}} alt="UCLA Logo" />
              <img src={BERKELEY_LOGO} loading={loadingStyle} style={{width: 150, maxWidth: "40%", opacity: 0.6}} alt="UC Berkeley Logo" />
            </div>
            <div style={{display: "flex", width: "100%", justifyContent: "space-around", alignItems: "center", marginTop: "4vh"}}>
              <img src={NORTHWESTERN_LOGO} loading={loadingStyle} style={{width: 150, maxWidth: "40%", opacity: 0.6}} alt="Northwestern University Logo" />
              <img src={CMU_LOGO} loading={loadingStyle} style={{width: 150, maxWidth: "40%", opacity: 0.6}} alt="Carnegie Mellon Logo" />
            </div>
          </div>
        )}
      </div>
    ) : (
      <div style={{opacity: opacity || 0.7, filter: useLightColors ? "invert(1)" : "none"}}>
        {!hideFirstRow && (
          <div style={{display: "flex", alignItems: "center", marginTop: "6vh", justifyContent: "space-around"}}>
            <div />
            <img src={STANFORD_LOGO} loading={loadingStyle} style={{width: 150, maxWidth: "15%", opacity: 0.6}} alt="Stanford School of Medicine Logo" />
            <img src={HARVARD_LOGO} loading={loadingStyle} style={{width: 150, maxWidth: "15%", opacity: 0.9}} alt="Harvard Medical School Logo" />
            <img src={UPENN_LOGO} loading={loadingStyle} style={{width: 150, maxWidth: "15%", opacity: 0.6}} alt="University of Pennsylvania Logo" />
            <img src={UCHICAGO_LOGO} loading={loadingStyle} style={{width: 150, maxWidth: "15%", opacity: 0.6}} alt="University of Chicago Logo" />
            <div />
          </div>
        )}
        {!hideSecondRow && (
          <div style={{display: "flex", alignItems: "center", marginTop: "4vh", justifyContent: "space-around"}}>
            <div />
            <img src={UCLA_LOGO} loading={loadingStyle} style={{width: 150, maxWidth: "15%", opacity: 0.6}} alt="UCLA Logo" />
            <img src={BERKELEY_LOGO} loading={loadingStyle} style={{width: 150, maxWidth: "15%", opacity: 0.6}} alt="UC Berkeley Logo" />
            <img src={CMU_LOGO} loading={loadingStyle} style={{width: 150, maxWidth: "15%", opacity: 0.6}} alt="Carnegie Mellon Logo" />
            <img src={NORTHWESTERN_LOGO} loading={loadingStyle} style={{width: 150, maxWidth: "15%", opacity: 0.6}} alt="Northwestern University Logo" />
            <div />
          </div>
        )}
      </div>
    )
  )
}

export default UniversitySourcesSection;