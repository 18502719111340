import {
  CERTIFICATION_VIDEO_PAGE_UPDATE_REQUEST_STATE,
  CERTIFICATION_VIDEO_PAGE_RESET_STATE,
} from '../constants/actionTypes'

import {
  REQ_SUBMIT_CERTIFICATION_VIDEO_PAGE_DETAILS,
} from '../constants/requestTypes'

import {
  REQUEST_UNSTARTED,
  REQUEST_SUCCESS
} from '../constants/requestStates'

import { combineReducers } from 'redux';


const defaultRequestResults = {
  [REQ_SUBMIT_CERTIFICATION_VIDEO_PAGE_DETAILS]: {state: REQUEST_UNSTARTED},
}
function requestResults(
  state = defaultRequestResults, action) {
  switch (action.type) {
    case CERTIFICATION_VIDEO_PAGE_UPDATE_REQUEST_STATE:
      return {...state, [action.key]: {...action.payload}};
    case CERTIFICATION_VIDEO_PAGE_RESET_STATE:
      return defaultRequestResults;
    default:
      return state;
  }
}

export default combineReducers({
  requestResults,
});
