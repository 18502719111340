import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  certificationVideoPageUpdateRequestState,
  certificationVideoPageTriggerRequest,
} from '../actions/certificationVideoPage';

import {
  toggleBodyScroll,
  hideLoadingScreen,
  initializeGA,
  pageViewGA,
  recordSignupFunnelEventGA,
  getAdvertisementIdFromQueryString,
  setAdvertisementReferralUserProperty,
  isGDPRSensitive
} from '../utils'

import TeamsOptInPage from '../components/TeamsOptInPage';

import {
  REQUEST_UNSTARTED,
} from '../constants/requestStates'

import {
  COMMON_INNER_HEIGHT,
  COMMON_EXPERIMENT_FLAGS_WEB,
  COMMON_SAFE_AREA_INSETS,
  COMMON_IS_NATIVE,
  COMMON_IS_IOS_DEVICE,
  COMMON_IS_ANDROID_DEVICE,
} from '../constants/commonState'

import {
  FORM_FIELD_HIDE_INTRO_VIDEO,
} from '../constants/formFields'

import {
  GA_LABEL_TEAMS_LOADED_PAGE,
} from '../constants/gaEventLabels'

class TeamsOptInPageContainer extends Component {
  async componentWillMount() {
    pageViewGA();
    await recordSignupFunnelEventGA(GA_LABEL_TEAMS_LOADED_PAGE)
  }

  render() {
    return (
      <TeamsOptInPage
        // State
        innerHeight={this.props.innerHeight}
        safeAreaInsets={this.props.safeAreaInsets}
        isIOSDevice={this.props.isIOSDevice}
        isAndroidDevice={this.props.isAndroidDevice}
        iOSAppIsAvailable={this.props.iOSAppIsAvailable}
        androidAppIsAvailable={this.props.androidAppIsAvailable}
        experimentFlags={this.props.experimentFlags}
        requestResults={this.props.requestResults}
        onUpdateRequestState={(requestType, body) => this.props.dispatch(certificationVideoPageUpdateRequestState(requestType, REQUEST_UNSTARTED, body))}
        onResetRequestState={requestType => this.props.dispatch(certificationVideoPageUpdateRequestState(requestType, REQUEST_UNSTARTED))}
        onTriggerRequest={requestType => this.props.dispatch(certificationVideoPageTriggerRequest(requestType))}
        // Actions
      />
    );
  }
}

TeamsOptInPageContainer.propTypes = {
  requestResults: PropTypes.object.isRequired,
  experimentFlags: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  innerHeight: PropTypes.number.isRequired,
  isIOSDevice: PropTypes.bool.isRequired,
  isAndroidDevice: PropTypes.bool.isRequired,
  safeAreaInsets: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  ...state.certificationVideoPage,
  experimentFlags: state.common[COMMON_EXPERIMENT_FLAGS_WEB],
  innerHeight: state.common[COMMON_INNER_HEIGHT],
  isIOSDevice: state.common[COMMON_IS_IOS_DEVICE],
  isAndroidDevice: state.common[COMMON_IS_ANDROID_DEVICE],
  safeAreaInsets: state.common[COMMON_SAFE_AREA_INSETS],
});

export default connect(mapStateToProps)(TeamsOptInPageContainer);
