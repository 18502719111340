import React from 'react'

import CompletionCheckIcon from './CompletionCheckIcon'

const MembershipFeatureDescription = ({
  text,
  marginTop,
  checkMarginTop,
  fontSize,
  fontFamily,
  lineHeight,
  textColor,
  checkColorComplete,
  checkboxBaseClassName
}) => (
  <div style={{display: "flex", alignItems: "top", justifyContent: "start", marginTop: marginTop || 10}}>
    <CompletionCheckIcon
      isComplete
      marginTop={checkMarginTop || 0}
      checkColorComplete={checkColorComplete || "#FFFFFF"}
      checkboxBaseClassName={checkboxBaseClassName || "membership-plan-button"}
    />
    <div style={{marginInlineStart: "0.5em", color: textColor || "#000000", fontSize: fontSize || "min(4vw, 16px)", lineHeight: lineHeight || undefined, fontFamily: fontFamily || "Futura"}}>
      {text}
    </div>
  </div>
)

export default MembershipFeatureDescription