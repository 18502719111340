import React from 'react';

import {
  SolidInfoSvg,
  AppleSvg,
  AndroidSvg,
} from '../../constants/svgs'

import StarRatings from 'react-star-ratings'

const getFillColor = (value, baselineAvailable, valueBaseline, valueBaselineStdDev, isIncreasingStatistic) => {
  if (!baselineAvailable) {
    return "#000000"
  } else {
    return "#000000"
    // const baselineDeviation = (value - valueBaseline)
    // if (Math.abs(baselineDeviation) < (1.5 * valueBaselineStdDev)) {
    //   return "#000000"
    // } else if (baselineDeviation > (1.5 * valueBaselineStdDev)) {
    //   return isIncreasingStatistic ? "#93E29A" : "#FF7CBC"
    // } else {
    //   return isIncreasingStatistic ? "#FF7CBC" : "#93E29A"
    // }
  }
}


const AppReviewPanel = ({
  title,
  reviewStars,
  dateString,
  username,
  occupation,
  countryFlag,
  platform,
  titleColor,
  titleFontFamily,
  description,
  maxTextLength
}) => {


  return (
    <div key={username} style={{minWidth: "min(80vw, 450px)", display: "inline-block", padding: "0px min(4%, 30px)", scrollSnapAlign: "start"}}>
      <div style={{padding: "min(3vw, 16px)", backgroundColor: "#EDEEF2", boxShadow: "0px 0px 1.5px 0.5px #dddddd", fontSize: "min(6vw, 25px)"}}>
        <div style={{display: "flex", alignItems: "top", justifyContent: "space-between"}}>
          <div style={{display: "inline-block", color: "#000000", fontFamily: "Futura", fontSize: "min(3.5vw, 18px)", lineHeight: "min(3.5vw, 18px)"}}>
            {title}
          </div>
          <div style={{display: "inline-block", color: "#999999", fontFamily: "Futura Light", minWidth: "18%", textAlign: "end", fontSize: "min(3.5vw, 18px)", lineHeight: "min(3.5vw, 18px)"}}>
            {dateString}
          </div>
        </div>
        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "min(1.5vw, 9px)"}}>
          {(reviewStars !== undefined) && (
            <div style={{display: "inline-block", lineHeight: "min(3.5vw, 18px)"}}>
              <StarRatings
                disabled
                rating={reviewStars}
                starRatedColor={"#FF8106"}
                starEmptyColor={"#FF8106"}
                starDimension={"min(3.5vw, 18px)"}
                starSpacing={"1px"}
                numberOfStars={5}
              />
            </div>
          )}
          <div style={{display: "flex", fontFamily: "Futura Light", fontSize: "min(3.5vw, 18px)", alignItems: "center"}}>
            <div style={{display: "inline-block", color: titleColor || "#999999", fontFamily: titleFontFamily || "Futura Light", fontSize: "min(3.5vw, 18px)", lineHeight: "min(3.5vw, 18px)"}}>
              {username}
            </div>
            {!!occupation && (
              <div style={{display: "inline-block", color: titleColor || "#999999", fontSize: "min(3.5vw, 18px)", fontSize: "min(4vw, 18px)", lineHeight: "min(4vw, 18px)", fontFamily: "Futura Medium", margin: "0px 0.2em"}}>
                {"•"}
              </div>
            )}
            {!!occupation && (
              <div style={{display: "inline-block", color: titleColor || "#999999", fontFamily: titleFontFamily || "Futura Light", fontSize: "min(3.5vw, 18px)", lineHeight: "min(3.5vw, 18px)"}}>
                {occupation}
              </div>
            )}
            <div style={{display: "inline-block", fontFamily: titleFontFamily || "Futura Light", marginInlineStart: "0.3em", fontSize: "min(3.5vw, 18px)", lineHeight: "min(3vw, 15px)"}}>
              {countryFlag}
            </div>
          </div>
        </div>
        {(!!description) && (
          <div style={{fontFamily: "Futura Light", fontSize: "min(3.5vw, 18px)", marginTop: "min(3vw, 16px)"}}>
            {((!!maxTextLength) && (description.length > maxTextLength)) ? (description.slice(0, maxTextLength) + "...") : description}
          </div>
        )}
      </div>
    </div>
  )
}

export default AppReviewPanel