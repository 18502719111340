import { applyMiddleware, createStore, combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createReduxHistoryContext } from 'redux-first-history';


import { history } from './history'

import common from './reducers/web';
import quizPage from './reducers/quizPage'
import certificationVideoPage from './reducers/certificationVideoPage'
import trainingRegistrationPage from './reducers/trainingRegistrationPage'

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({ 
  history: history,
  //other options if needed 
});

const getMiddleware = () => {
  if (process.env.NODE_ENV === 'production') {
    return applyMiddleware(routerMiddleware)
  } else {
    // Enable additional logging in non-production environments.
    return applyMiddleware(routerMiddleware, createLogger())
  }
};

const store = createStore(
	combineReducers({
	  common,
	  quizPage,
	  certificationVideoPage,
	  trainingRegistrationPage,
	  router: routerReducer
	}),
	composeWithDevTools(getMiddleware())
);

export { store }
