import React from 'react';


const PendingResultsPanel = ({
  title,
  pendingText,
  backgroundColor,
  marginTop,
  color,
  showButton,
  buttonText,
  onButtonClick,
  imgSrc,
  maxImgWidth
}) => {

  return (
    <div style={{marginTop: marginTop || "min(6vw, 25px)", padding: "min(4vw, 20px)", backgroundColor: backgroundColor || "#ffffff", boxShadow: "0px 1px 1.5px 0.5px #dddddd", fontSize: "min(6vw, 25px)"}}>
      <div style={{display: "flex", alignItems: "center", justifyContent: "start"}}>
        <div style={{display: "inline-block", textTransform: "uppercase", fontFamily: "Futura", fontSize: "min(5vw, 22px)", lineHeight: "min(5vw, 22px)", color: "#000000"}}>
          {title}
        </div>
      </div>
      <div style={{display: "flex", alignItems: "center", justifyContent: "start", marginTop: "max(1.5vw, 10px)"}}>
        <div style={{fontFamily: "Futura Light", fontSize: "min(4vw, 18px)", color: color || "#babdc6"}}>
          {`${pendingText}`}
        </div>
      </div>
      {!!(imgSrc) && (
        <div style={{backgroundColor: "#FFFFFF", display: "flex", justifyContent: "center"}}>
          <img style={{width: `100%`, maxWidth: maxImgWidth, margin: "min(2vw, 12px) 0px"}} src={imgSrc} />
        </div>
      )}
      {showButton && (
        <button onClick={() => onButtonClick()} style={{marginTop: "max(2vw, 12px)", textTransform: "uppercase", border: "none", fontFamily: "Futura", fontSize: "min(5vw, 22px)", padding: "6px 8px", width: "100%", color: "#FFFFFF", backgroundColor: "#000000"}}>
          {`${buttonText}`}
        </button>
      )}
    </div>
  )
}

export default PendingResultsPanel