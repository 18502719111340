export const COMMON_APP_NAME = 'appName'
export const COMMON_APP_PLATFORM = 'appPlatform'
export const COMMON_RESET_PASSWORD_TOKEN = 'resetPasswordToken'
export const COMMON_CLIENT_TESTIMONIAL_TOKEN = 'clientTestimonialToken'
export const COMMON_INNER_HEIGHT = 'innerHeight'
export const COMMON_INNER_WIDTH = 'innerWidth'
export const COMMON_VISIBLE_KEYBOARD_HEIGHT = 'visibleKeyboardHeight'
export const COMMON_SAFE_AREA_INSETS = 'safeAreaInsets'
export const COMMON_ANDROID_DEFERRED_PROMPT = 'androidDeferredPrompt'
export const COMMON_IS_IOS_SAFARI = 'isIOSSafari'
export const COMMON_IS_IOS_PWA = 'isIOSPWA'
export const COMMON_IS_IOS_DEVICE = 'isIOSDevice'
export const COMMON_IS_ANDROID_DEVICE = 'isAndroidDevice'
export const COMMON_IOS_VERSION = 'iOSVersion'
export const COMMON_IS_NATIVE = 'isNative'
export const COMMON_NATIVE_APP_OPEN_COUNT = 'nativeAppOpenCount'
export const COMMON_PUSH_NOTIFICATION_TOKEN = 'pushNotificationToken'
export const COMMON_IN_APP_PURCHASE_PRODUCTS_LOADED = 'inAppPurchaseProductsLoaded'
export const COMMON_IN_APP_PURCHASE_PRODUCTS_LOAD_ERROR = 'inAppPurchaseProductsLoadError'
export const COMMON_IN_APP_PURCHASE_PRODUCTS = 'inAppPurchaseProducts'
export const COMMON_IN_APP_PURCHASE_PRODUCTS_INTRO_TRIAL_AVAILABLE = 'inAppPurchaseProductsIntroTrialAvailable'
export const COMMON_CAMERA_ACCESS_PERMISSION_STATUS = 'cameraAccessPermissionStatus'
export const COMMON_APP_LOADED_AT = 'appLoadedAt'
export const COMMON_DEEPLINKED_ARTICLE_KEY = 'deeplinkedArticleKey'
export const COMMON_DEEPLINKED_AFFILIATE_CODE = 'deeplinkedAffiliateCode'
export const COMMON_APP_STATE_IS_ACTIVE = 'appIsActive'
export const COMMON_CURRENT_APP_VERSION = 'currentAppVersion'
export const COMMON_NATIVE_COMPLETION_SOUND_PRELOADED = 'completionSoundPreloaded'
export const COMMON_APP_ACTION_TYPE = 'appActionType'
export const COMMON_IS_DEEPLINKED_PAYWALL_ACTION = 'isDeeplinkedPaywallAction'
export const COMMON_IS_DEEPLINKED_DISCOVERY_QUIZ_ACTION = 'isDeeplinkedDiscoveryQuizAction'
export const COMMON_QUIZ_RESULT_KEY = 'quizResultKey'
export const COMMON_CERTIFICATION_AFFILIATE_CODE = 'certificationAffiliateCode'
export const COMMON_DEEPLINKED_CERT_PAGE_SECTION_KEY = 'certificationPageSectionKey'
export const COMMON_DEEPLINKED_CERT_TESTIMONIAL_CATEGORY_KEY = 'certificationTestimonialCategoryKey'
export const COMMON_DEEPLINKED_CERT_CHECKOUT_PLAN_KEY = 'certificationCheckoutPlanKey'
export const COMMON_DEPLOYMENT_CONFIG = 'commonDeploymentConfig'
export const COMMON_NATIVE_APP_IS_OFFLINE = 'nativeAppIsOffline'
export const COMMON_EXPERIMENT_FLAGS = 'experimentFlags'
export const COMMON_EXPERIMENT_FLAGS_WEB = 'experimentFlagsWeb'
export const COMMON_DEVICE_IDENTIFIER = 'deviceIdentifier'
export const COMMON_SELECTED_APP_ICON = 'selectedAppIcon'
export const COMMON_EXISTING_APP_OPEN_URL = 'existingAppOpenUrl'
export const COMMON_IS_MAC_OS_RUNTIME = 'isMacOSRuntime'
export const COMMON_LANGUAGE_CODE = 'languageCode'