import React from 'react';


import { CSSTransition } from 'react-transition-group';
import { range } from 'lodash-es';

import CoachProfileDetails from './common/CoachProfileDetails'

import { history } from '../history'

import {
  AppleSvg,
  AndroidSvg,
  NeuroFitNamedLogoSvg,
} from '../constants/svgs'

import {
  GA_LABEL_CLICKED_REGISTER_FOR_CERTIFICATION_TRAINING
} from '../constants/gaEventLabels'

import {
  REQ_SUBMIT_CERTIFICATION_VIDEO_PAGE_DETAILS,
} from '../constants/requestTypes'

import {
  EXPERIMENT_FLAGS_WEB_CERT_OFFER_USE_VSL,
} from '../constants/experimentFlags'

import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../constants/requestStates'

import {
  subdomainUrls,
  recordLinkClickGA,
  ProductionCertificationRegistrationUrl,
  NeuroFitUniversalAppStoreLink,
  NeuroFitAppleAppStoreLink,
  NeuroFitGooglePlayStoreLink,
  CertificationCheckoutLink,
  recordPixelEventIfAvailable,
  recordLinkClickAndRedirect,
  identifyCertificationAnalyticsWebUser,
  configureAnalyticsUserProperties,
  getPixelParams,
  recordFunnelEventGA,
  delay,
  getCoachProfileLink,
  validateUrl,
} from '../utils'

import Helmet from './common/Helmet'

import agent from '../agent'

class CoachProfilePage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      headerBackgroundColor: "transparent",
      dropShadowColor: "transparent",
      headerBorderColor: "transparent",
      headerBackgroundBlur: "none",
      logoOutlineColor: "#EDEEF2",
      topPageScrollProgress: 0,
      fetchCoachProfileResultState: REQUEST_UNSTARTED,
      coachProfileDetails: null,
      requestCoachingSessionResultState: REQUEST_UNSTARTED,
      requestCoachingSessionResult: null
    }
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      formFields,
      isNative,
      isIOSDevice,
      isAndroidDevice,
      requestResults,
      onTriggerRequest,
      onUpdateRequestState,
      experimentFlags,
      coachProfileDetails,
      username,
      isValidPersonalUrl
    } = this.props

    const {headerBorderColor, fetchCoachProfileResultState, requestCoachingSessionResultState, requestCoachingSessionResult, headerBackgroundColor, headerBackgroundBlur, logoOutlineColor, textShadow, topPageScrollProgress} = this.state
    
    const appDownloadUrl = isIOSDevice ? NeuroFitAppleAppStoreLink : (isAndroidDevice ? NeuroFitGooglePlayStoreLink : NeuroFitUniversalAppStoreLink)

    return (
      <div style={{height: innerHeight}}>
        {(!!coachProfileDetails) && (
          <Helmet
            title={`${coachProfileDetails.first_name} ${coachProfileDetails.last_name} | ${coachProfileDetails.occupation} | NEUROFIT Certified Coach`}
            description={`I'm a ${(coachProfileDetails.years_professional_experience > 0) ? `${coachProfileDetails.years_professional_experience}-Year` : ""}${coachProfileDetails.occupation} and NEUROFIT-Certified Somatics + Nervous System Coach - book a session with me now. ${coachProfileDetails.coaching_niche_description}`}
            href={getCoachProfileLink(username)}
          />
        )}
        <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden", backgroundColor: "#CFD2DD"}}>
          <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
          <CoachProfileDetails
            innerHeight={innerHeight}
            safeAreaInsets={safeAreaInsets}
            isNative={isNative}
            coachProfileDetails={coachProfileDetails}
            onContainerScroll={updatedState => this.setState({...updatedState})}
            experimentFlags={experimentFlags}
            isValidPersonalUrl={isValidPersonalUrl}
          />
        </div>
      </div>
    )
  }
}

export default CoachProfilePage;