import React from 'react'

import {
  NeuroFitUniversalAppStoreLink,
  copyToClipboard,
} from '../../utils'

import MembershipFeatureDescription from '../common/MembershipFeatureDescription'

import { CSSTransition, SwitchTransition} from 'react-transition-group';

const exitButtonStyle = {pointerEvents: "all", margin: "15px 25px", height: 50, width: 50, backgroundColor: "#353F6A", borderRadius: "50%", boxShadow: "0px 0px 10px #353F6A", border: "none"}


class AppPaymentResultModal extends React.Component {

  constructor(props){
     super(props)

     this.state = {
      isEmailCopied: false
     }
  }

  render() {

    const {
      // State
      show,
      checkoutEmailAddress,
      safeAreaInsets,
      innerHeight,
      paymentSuccess,
      onClose
    } = this.props

    const {
      isEmailCopied
    } = this.state

    return (
      <CSSTransition
        in={show}
        timeout={500}
        classNames="redeem-access-code-modal"
        unmountOnExit
      >
        <div
          style={{
            position: 'fixed',
            top: safeAreaInsets.top,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: show ? "rgba(0, 0, 0, 0.2)" : "transparent",
            backdropFilter: show ? "blur(4px)" : "none",
            WebkitBackdropFilter: show ? "blur(4px)" : "none",
            zIndex: 1000000
          }}
        >
          <div 
            id={"app-offline-modal"}
            style={{
              position: 'absolute',
              background: 'white',
              border: "2px black solid",
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              outline: 'none',
              width: "calc(95% - 4px)",
              maxWidth: 600,
              left: 0,
              right: 0,
              margin: "0px auto",
              top: (safeAreaInsets.top) / 2 + (innerHeight / 4),
              height: Math.min(600, (innerHeight - safeAreaInsets.top) / 2),
            }}
            onClick={e => e.stopPropagation()}
          >
            <div style={{color: "white", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
              <div style={{width: "85%", color: "#000000", paddingTop: "15%"}}>
                <div>
                  {paymentSuccess ? (
                    <div>
                      <div style={{fontSize: "min(6vw, 26px)"}}>
                        <div style={{fontFamily: "Futura"}}>
                          {"Welcome to NEUROFIT!"}
                        </div>
                        <div style={{paddingTop: "min(6vw, 26px)", fontFamily: "Futura Book"}}>
                          <span>{"Download the app "}</span>
                          <a href={NeuroFitUniversalAppStoreLink} style={{color: "#000000", fontFamily: "Futura Medium"}} target="_blank" rel="noopener noreferrer nofollow">
                            {"here"}
                          </a>
                        </div>
                        <div style={{paddingTop: "min(4vh, 20px)", fontFamily: "Futura Book"}}>
                          <span>{"Then log into your account with this email address:"}</span>
                        </div>
                        <div
                          onClick={() => {
                            this.setState({isEmailCopied: true})
                            navigator.clipboard.writeText(checkoutEmailAddress)
                            setTimeout(() => {
                              this.setState({isEmailCopied: false})
                            }, 1500)
                          }}
                          style={{fontFamily: "Futura Medium", paddingTop: "min(2vw, 10px)", fontSize: "min(6vw, 26px)", color: isEmailCopied ? "#777777" : "#000000", maxWidth: "100%", cursor: isEmailCopied ? "not-allowed" : "pointer", wordBreak: "break-word", textDecoration: isEmailCopied ? "none" : "underline"}}
                        >
                          {isEmailCopied ? "COPIED TO CLIPBOARD" : checkoutEmailAddress}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div style={{fontSize: "min(6vw, 24px)", textTransform: "uppercase", fontFamily: "Futura"}}>
                        {"UPDATE PAYMENT DETAILS"}
                      </div>
                      <div style={{paddingTop: "min(2vw, 10px)", fontSize: "min(4vw, 18px)"}}>
                        <div style={{fontFamily: "Futura Light", fontSize: "min(4vw, 18px)"}}>
                          {"We weren't able to process your payment using the card you entered - please try again with a different card."}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    )
  }
}

export default AppPaymentResultModal;