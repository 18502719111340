import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  resetCertificationVslPage,
  certificationVideoPageUpdateRequestState,
  certificationVideoPageTriggerRequest,
} from '../actions/certificationVideoPage';

import {
  toggleBodyScroll,
  hideLoadingScreen,
  initializeGA,
  pageViewGA,
  recordSignupFunnelEventGA,
  getAdvertisementIdFromQueryString,
  setAdvertisementReferralUserProperty,
  isGDPRSensitive,
} from '../utils'

import CertificationVslPage from '../components/CertificationVslPage';

import {
  REQUEST_UNSTARTED,
} from '../constants/requestStates'

import {
  COMMON_INNER_HEIGHT,
  COMMON_SAFE_AREA_INSETS,
  COMMON_IS_NATIVE,
  COMMON_IS_IOS_DEVICE,
  COMMON_IS_ANDROID_DEVICE,
} from '../constants/commonState'

import {
  FORM_FIELD_HIDE_INTRO_VIDEO,
} from '../constants/formFields'

import {
  GA_LABEL_LOADED_CERTIFICATION_VSL_PAGE,
} from '../constants/gaEventLabels'

class CertificationVslPageContainer extends Component {
  async componentWillMount() {
    await initializeGA(isGDPRSensitive/*disableCookies*/);
    pageViewGA();
    await recordSignupFunnelEventGA(GA_LABEL_LOADED_CERTIFICATION_VSL_PAGE)
    const advertisementId = getAdvertisementIdFromQueryString()
    if (!!advertisementId) {
      await setAdvertisementReferralUserProperty(advertisementId)
    }
  }

  componentDidMount() {
    hideLoadingScreen()
  }
  render() {
    return (
      <CertificationVslPage
        // State
        innerHeight={this.props.innerHeight}
        safeAreaInsets={this.props.safeAreaInsets}
        isIOSDevice={this.props.isIOSDevice}
        isAndroidDevice={this.props.isAndroidDevice}
        iOSAppIsAvailable={this.props.iOSAppIsAvailable}
        androidAppIsAvailable={this.props.androidAppIsAvailable}
      />
    );
  }
}

CertificationVslPageContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  innerHeight: PropTypes.number.isRequired,
  isIOSDevice: PropTypes.bool.isRequired,
  isAndroidDevice: PropTypes.bool.isRequired,
  safeAreaInsets: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  ...state.certificationVideoPage,
  innerHeight: state.common[COMMON_INNER_HEIGHT],
  isIOSDevice: state.common[COMMON_IS_IOS_DEVICE],
  isAndroidDevice: state.common[COMMON_IS_ANDROID_DEVICE],
  safeAreaInsets: state.common[COMMON_SAFE_AREA_INSETS],
});

export default connect(mapStateToProps)(CertificationVslPageContainer);
