import React from 'react'

const SettingsLinkButton = ({
  title,
  icon,
  href,
  isDisabled,
  isCopied,
  onClick
}) => {
  return (
    <a
      href={href}
      style={{display: "block", textDecoration: "none", textTransform: "uppercase", width: "100%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", opacity: isCopied ? 0.7 : 1.0, fontFamily: "Futura", padding: "min(4.5vw, 18px) 0px", fontSize: "min(6vw, 25px)", marginTop: "min(4vh, 25px)", color: "#000000", backgroundColor: "#ffffff", textAlign: "center", boxShadow: "0px 1px 1.5px 0.5px #dddddd", border: "none"}}
      disabled={isDisabled}
      target={"_blank"}
      onClick={() => onClick()}
    >
      {title}
    </a>
  );
}

export default SettingsLinkButton;