import React from 'react'


import { CSSTransition, SwitchTransition} from 'react-transition-group';

import SettingsButton from '../common/SettingsButton'
import LoadingLogo from '../common/LoadingLogo'

import {
  BackArrowSvg
} from '../../constants/svgs'

const exitButtonStyle = {pointerEvents: "all", margin: "15px 25px", height: 50, width: 50, backgroundColor: "#353F6A", borderRadius: "50%", boxShadow: "0px 0px 10px #353F6A", border: "none"}


const containerStyle = {textAlign: "start", width: "100vw", overflowX: "hidden", overflowY: "scroll"}

const headerTextStyle = {fontSize: "min(7vw, 32px)", margin: "min(9vh, 45px) 0px min(6vh, 30px) 0px", color: "#000000", textAlign: "start", fontFamily: "Futura"}


class QrCodeDownloadModal extends React.Component {

  constructor(props){
     super(props)
     this.state = {
      downloadQrCodeLoaded: false
     }
   }

  render() {

    const {
      // State
      show,
      innerHeight,
      onHide,
      safeAreaInsets
    } = this.props

    const {downloadQrCodeLoaded} = this.state

    return (
      <CSSTransition
        in={show}
        timeout={500}
        classNames="fade-in"
        unmountOnExit
      >
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1000000,
            backdropFilter: show ? "blur(10px)" : "blur(10px)",
            WebkitBackdropFilter: show ? "blur(10px)" : "blur(10px)",
          }}
          onClick={(e) => {
            onHide()
            e.stopPropagation()
          }}
        >
          <div 
            id={"neurofit-intro-modal"}
            style={{
              position: 'absolute',
              backgroundColor: "rgba(237, 238, 242, 0.85)",
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              outline: 'none',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}
            onClick={e => e.stopPropagation()}
          >
            <div style={{color: "#000000", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", height: innerHeight}}>
              <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
              <div style={{display: "flex", alignItems: "top", height: 40, padding: 10, justifyContent: "space-between", width: "calc(100% - 20px)", position: "fixed", top: safeAreaInsets.top, left: 0, zIndex: 1000, boxShadow: "0px 2px 0px 0px #000000", backgroundColor: "#ffffff", zIndex: 999}}>
                <span
                  className="close-black"
                  style={{opacity: 0.0, transition: "opacity 200ms linear"}}
                  disabled
                >
                  <BackArrowSvg />
                </span>
                <div style={{color: "#000000", textAlign: "center", fontSize: "min(6vw, 30px)", height: 40, lineHeight: "40px", fontFamily: "Futura"}}>
                  {"DOWNLOAD NEUROFIT"}
                </div>
                <span
                  className="close-black"
                  onClick={() => {
                    onHide()
                  }}
                >
                  &times;
                </span>
              </div>
              <div 
                className={"disable-scrollbars"}
                style={{...containerStyle, height: innerHeight}}
              >
                <div
                  style={{textAlign: "start", width: "100vw", height: innerHeight}}
                >
                  <div style={{color: "white", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", paddingTop: `calc(60px + 8vh)`}}
                    >
                    <div style={{width: "85%", maxWidth: 600, color: "#000", marginBottom: 10}}>
                      <div style={{color: "#000000", marginBottom: safeAreaInsets.bottom + 30, backgroundColor: "transparent"}}>
                        <div style={{fontSize: "min(7.5vw, 32px)", fontFamily: "Futura", marginBottom: "2vh"}}>
                          {"DOWNLOAD THE NEUROFIT APP"}
                        </div>
                        <div style={{fontFamily: "Futura Book", fontSize: "min(5.5vw, 22px)", textAlign: "start"}}>
                          {`Please scan the below QR Code with your phone camera,`}
                        </div>
                        <div style={{fontFamily: "Futura Book", marginBottom: "4vh", fontSize: "min(5.5vw, 22px)", textAlign: "start"}}>
                          {`or search "NEUROFIT" on the App Store or Play Store.`}
                        </div>


                        <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                          <SwitchTransition mode="out-in">
                            <CSSTransition
                              timeout={400}
                              classNames={"fade-in"}
                              unmountOnExit
                              addEndListener={(node, done) => {
                                node.addEventListener("transitionend", done, false);
                              }}
                              key={downloadQrCodeLoaded}
                            >
                              <div 
                                style={{width: 200, maxWidth: "50vw", marginTop: 60, height: 200, maxHeight: "50vw"}}
                                className={!downloadQrCodeLoaded ? "article-loading-background-animation" : "article-loaded-background"}
                                >
                                <img
                                  alt={"NeuroFit App Download QR Code"}
                                  src={"/images/qr-code-neurofit-download.png"}
                                  onLoad={() => this.setState({downloadQrCodeLoaded: true})}
                                  style={{width: "100%", height: "100%", display: downloadQrCodeLoaded ? "block" : "none", opacity: (downloadQrCodeLoaded ? 1.0 : 0.0), transition: "opacity 250ms linear"}}
                                />
                              </div>
                            </CSSTransition>
                          </SwitchTransition>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    )
  }
}

export default QrCodeDownloadModal